import React from 'react';
import '../loaders/loader.css'
class BtnLoader extends React.Component {
    constructor(props){
        super(props)
        this.whiteStyle = {border : '2px solid white', top : '-2px', borderColor: 'white transparent transparent transparent'}

    }
    
    render(){
      
            return (
                        <div style={this.props.style != undefined ? this.props.style : {}}>
                            <div className="lds-ring-btn">
                                <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                                <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                                <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                                <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                            </div>
                        </div>
                
            ); 
        
        
    }
}
export default BtnLoader;
