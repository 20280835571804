import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'
import account_permitted from '../custom_icons/account_permitted.svg'
var strings = getLocalizedStrings()

class SuccessSessionTooltip extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
      
            return (
                <div className="session_banner top_m">
                    <div onClick={this.props.toogleSuccesBanner} className="tooltip_bg_withdrow"></div>
                    <i className="session_banner_arrow"></i>
                    <div className="session_banner_line"></div>
                    <div className="session_banner_container">
                        <div className="flex">
                            <img className="session_success_icon" src={account_permitted}/>
                            <p className="session_banner_title">{strings.SESSION_SUCCESS_TITLE}</p>
                        </div>
                        <p className="session_banner_text">{strings.SESSION_SUCCESS_TEXT}</p>
                        <p onClick={this.props.toogleSuccesBanner} className="session_banner_btn hover">{strings.QUESTION_OK}</p>
                    </div>
                </div>
            ); 
        
        
    }
}
export default SuccessSessionTooltip;
