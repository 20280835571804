/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import profile_shop from '../custom_icons/profile_shop.svg'
import { getButtonsStyle, getIsTagsEuro, getUrlParameter } from '../../utils/Utils';

var strings = getLocalizedStrings()
class NewBuyCoinsBtn extends React.Component {
    constructor(props){
        super(props)
        this.confirmPayment = this.confirmPayment.bind(this);
    }
    confirmPayment(){
        this.props.buyCoins(this.props.coinsCount, this.props.realPrice, this.props.fullPrice)
    }
    
    render(){
        
        const buttonStyle = getButtonsStyle()
        const isTagsEuro = getIsTagsEuro()
        return (
                    <div onClick={this.confirmPayment} className={this.props.type == 0 ? "buy_plashka left_right_plashka left_plashka hover" : this.props.type == 1 ? "buy_plashka center_plashka hover" : "buy_plashka left_right_plashka right_plashka hover"}>
                        <p className="buy_plashka_title">{this.props.coins} <br/>{strings.COIN_5}</p>
                        <div  className="flex desc_container_buy">
                            <p className="buy_plashka_desc">{strings.thisIs} {this.props.coinsCount/10}</p>
                            <img alt='' className="profile_shop_img" src={profile_shop}/>
                        </div>
                        <div className="flex desc_container_buy">
                                <p className="shop_price_text_left">{this.props.fullPrice}₽</p>
                                <p className="shop_price_text_right">{this.props.realPrice}₽</p>
                        </div>
                        <p style={isTagsEuro ? this.props.type == 1 ? buttonStyle : {color : 'black'} : {}}  className={this.props.type == 1 ? "shop_buy_btn_new center_shop_btn center_hor_result" : "shop_buy_btn_new"}>{strings.BUY}</p>                        
                    </div>
        ); 
        
        
    }
}
export default NewBuyCoinsBtn;
