
export const videoIconStyle = (photo) => {
    return getViewStyle(photo, "flex");
  };
  export const videoViewsStyle = (photo) => {
    return getViewStyle(photo, "block");
  };
  export const commentsOffStyle = (photo) => {
    if (photo != undefined){
        if (photo.comments_disabled == undefined) {
            return {display : 'none', visibility : 'collapse'}
        } else if (photo.comments_disabled) {
            return {display : 'block', visibility : 'visible'}
        }
    }
};

var yellow = {color : 'var(--warn)'}
var green = {color : 'var(--green)'}
var black = {color : 'black'}

export const getProgressColorLike = (photo) => {
    if (photo == undefined){
      return yellow
    }
    if (photo.our_info_photo != undefined) {
      if (photo.our_info_photo.count_likes >= (photo.our_info_photo.all_coins / 2)) {
        return green
      } else {
        return yellow
      }
    } else {
      return black
    }
  }
  export const getProgressColorComment = (photo) => {
    if (photo == undefined) {
      return yellow
    }
    if (photo.our_info_extra_2 != undefined) {
      if (photo.our_info_extra_2.count_coins == 0) {
        return green
      } else {
        return yellow
      }
    } else {
      return black
    }
  }
  export const getProgressColorView = (photo) => {
    if (photo == undefined) {
      return yellow
    }
    if (photo.our_info_extra_1 != undefined) {
      if (photo.our_info_extra_1.count_coins == 0) {
        return green
      } else {
        return yellow
      }
    } else {
      return black
    }
  }
  export const getViewStyle = (photo, style) =>{
    var displayStyle = {display : style}
    var displayNone =   {display : 'none'}
    
    if (photo == undefined){
      return displayNone
    }
    switch (photo.media_type) {
      case 8:
      return displayNone
      case 1:
      return displayNone
      case 2:
      return displayStyle
      default:
      return displayNone
    }
  }

  export const getProgress = (photoInfo) => {
    var multiplier = 2;

    if (photoInfo == undefined) {
      return "0/0"
    }
    if (photoInfo.type != undefined) {
      multiplier = 10;
    }
    return ((photoInfo.all_coins / multiplier) - (photoInfo.count_coins / multiplier)) + "/" + (photoInfo.all_coins / multiplier)
  };

  export const updateProgress = (photoInfo, addedCoins, isExtra) => {
    if (photoInfo == undefined) {
      var info = {
        "all_coins" : addedCoins,
        "count_coins" : addedCoins
      }
      if (isExtra){
        info.type = 1
      }
      return info
    } else {
      var info = photoInfo
      info.all_coins = (parseInt(photoInfo.all_coins) + addedCoins)
      info.count_coins = (parseInt(photoInfo.count_coins) + addedCoins)
      return info
    }
  };
  export const getCountMoneyTask = (photoInfo) => {
    var multiplier = 2;

    if (photoInfo == undefined) {
      return 0
    }
    if (photoInfo.type != undefined) {
      multiplier = 10;
    }
    return ((photoInfo.all_coins / multiplier) - (photoInfo.count_coins / multiplier))
  };
  export const getSource = (photo) => {
    if (photo == undefined){
      return ""
    }
    switch (photo.media_type) {
      case 8:
      return photo.carousel_media[0].image_versions2.candidates[0].url;
      case 1:
      return photo.image_versions2.candidates[0].url;
      case 2:
      return photo.image_versions2.candidates[0].url;
      default:
      return "";
    }
    //photo.image_versions2 ? photo.image_versions2.candidates[0].url : 'https://placehold.it/320x320'
  }
  export const getSource2 = (photo) => {
    if (photo == undefined){
      return ""
    }
    switch (photo.media_type) {
      case 8:
      return photo.carousel_media[0].image_versions2.candidates[0].url2;
      case 1:
      return photo.image_versions2.candidates[0].url2;
      case 2:
      return photo.image_versions2.candidates[0].url2;
      default:
      return "";
    }
    //photo.image_versions2 ? photo.image_versions2.candidates[0].url : 'https://placehold.it/320x320'
  }
  export const getMobileDisplayStyle = (photo, index) => {

    var displayStyle = {width : 'calc(100% / 3)', marginRight : '16px'}
    if (photo == undefined) {
      return displayStyle
    }
    
    if (photo.media_type != 2) {
      if (index == 2){
        if (photo.comments_disabled) {
          return {display : 'none'}
        }
        displayStyle = {width : 'calc(100% / 2)', marginRight : '0px'}
      } else if (index == 3) {
        return {display : 'none'}
      }  else {
        displayStyle = {width : 'calc(100% / 2)', marginRight : '16px'}
      }
    } else {
      if (index == 3) {
        return {width : 'calc(100% / 3)', marginRight : '0px'}
      } else {
        return displayStyle
      }
    }
    return displayStyle
  }
  export const getStyleDecision = (boolVal) => {
    if (boolVal) {
      return {display : 'flex'}
    } else {
      return {display : 'none'}
    }
  }
  export const inProccesDecizion = (photo) => {
    var isCommentsInprocess = (photo.our_info_extra_2 != undefined) && (photo.our_info_extra_2.count_coins > 1)
    var isViewsInprocess = (photo.our_info_extra_1 != undefined) && (photo.our_info_extra_1.count_coins > 1)
    var isLikesInprocess = (photo.our_info_photo != undefined) && (photo.our_info_photo.count_coins > 1)
    return isLikesInprocess || isCommentsInprocess || isViewsInprocess
  }
  export const isStartCampaign = (photo) => {
    return getStyleDecision(photo.our_info_photo == undefined && photo.our_info_extra_1 == undefined && photo.our_info_extra_2 == undefined);
  };
  export const isFinishedCampaign = (photo) => {
    if (!inProccesDecizion(photo)){
      var isCommentsFinished = (photo.our_info_extra_2 != undefined) && (photo.our_info_extra_2.count_coins <= 1)
      var isViewsFinished = (photo.our_info_extra_1 != undefined) && (photo.our_info_extra_1.count_coins <= 1)
      var isLikesFinished = (photo.our_info_photo != undefined) && (photo.our_info_photo.count_coins <= 1)
      return getStyleDecision(isLikesFinished || isCommentsFinished || isViewsFinished);
    } else {
      return getStyleDecision(false);
    }
    
  };
  export const isInprocessCampaign = (photo) =>{
    return getStyleDecision(inProccesDecizion(photo));
  };


  export const getLowUrl = (photo) => {

  switch (photo.media_type) {
    case 8:
    return photo.carousel_media[0].image_versions2.candidates[1].url;
    case 1:
    return photo.image_versions2.candidates[1].url;
    case 2:
    return photo.image_versions2.candidates[1].url;
    default:
    return "";

  }
}

export const getStandartUrl = (photo) =>{

  switch (photo.media_type) {
    case 8:
    return photo.carousel_media[0].image_versions2.candidates[0].url;
    case 1:
    return photo.image_versions2.candidates[0].url;
    case 2:
    return photo.image_versions2.candidates[0].url;
    default:
    return "";

  }
}
export const getIsPhoto = (photo) =>{

  switch (photo.media_type) {
    case 8:
    return 1;
    case 1:
    return 1;
    case 2:
    return 0;
    default:
    return 1;

  }
}
export const getEnabledBtnStyle = () =>{
  return  {pointerEvents: "none",opacity: "1", background : "#cdaee8"}
}