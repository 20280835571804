import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'

var strings = getLocalizedStrings()

class DoFirstTask extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
      
            return (
                <div className="session_banner top_m">
                    <div onClick={this.props.toogleDoFirstTask} className="tooltip_bg_withdrow"></div>
                    <i className="session_banner_arrow"></i>
                    <div className="session_banner_line"></div>
                    <div className="session_banner_container">
                        <p className="session_banner_title">{strings.SESSION_DIALOG_TITLE}</p>
                        <p className="session_banner_text">{strings.SESSION_DIALOG_TEXT_1}</p>
                        <p className="session_banner_text">{strings.DO_TASKS_DIALOG_4}</p>
                        <p onClick={this.props.toogleDoFirstTask} className="session_banner_btn hover">{strings.DO_TASKS}</p>
                    </div>
                </div>
            ); 
        
        
    }
}
export default DoFirstTask;
