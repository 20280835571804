/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import '../css_android/social.css'
import arrow_filter from './custom_views/custom_icons/drop_arrow.svg'
// import UpBalanceUniversal from './custom_views/universal_tasks/UpBalanceUniversal';
import FilterDropdown from './utils/FilterDropdown';
import Loader from './utils/LoaderAndroid';
import { declension, getHttpParams, getRequestUrl } from './utils/Utils';
import { arrowRotateStyle, formatSocialConfig, getSnItems, getSocialHistoryIcon, isValidUrl } from './utils/SocialHelper';
import { getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from './utils/LocalizedStrings';
import BtnLoader from './custom_views/loaders/BtnLoader';

var strings = getLocalizedStrings()
function Social(props) {
    
    const [isLoading, setIsLoading] = useState(false)
    const [historyLoading, setHistoryLoading] = useState(false)
    const [isShowFilters, setShowFilters] = useState(false)
    const [isShowUpBalance, setShowUpBalance] = useState(false)
    const [toastText, setToastText] = useState("")
    const [snItems, setSnItems] = useState(getSnItems())
    const [filters, setFilters] = useState([])
    const [type, setType] = useState("")
    const [config, setConfig] = useState({})
    const [url, setUrl] = useState("")
    const [count, setCount] = useState(100)
    const [autoLikesCount, setAutoLikesCount] = useState(1)
    const [history, setHistory] = useState([])
    const [pollIndex, setPollIndex] = useState(1)
    const priceItem = 10
    const borderError = {border: '1px solid rgba(255, 98, 98, 0.5)'}
    
    // const tgConditions = "Каналу или группе должно быть не менее 2-х недель. Запрещенные материалы: завышенные неподкреплённые обещания (заработок, товары), займы, порнография, казино и другие азартные игры, нацизм, наркотики, призыв к суициду, накрутка, экстремизм, политика.\n\nУслуга не подходит для чатов, так как будут большие списания. Учитывать это при заказе."
    useEffect(() => {
        var params = getRequestParams()
        fetch("https://upmob.ru/api/getLikedromConfig", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData) {
                setConfig(responseData)
                const config = formatSocialConfig(responseData, priceItem)
                setConfig(config)
                onItemsChanged(snItems, config)
            }  
        })
        getMyOrders()
    }, [])
    useEffect(() => {
       setShowUpBalance(false)
    }, [type, url, count])
   
    const onItemPicked = (item) => {
        var mItems = [...snItems]
        mItems.forEach(element => {
            element.isSelected = element.index == item.index
        })
        setSnItems(mItems)
        onItemsChanged(mItems, config)
    }
    const onItemsChanged = (filterItems, config) => {
        const items = config[filterItems.filter(i => i.isSelected)[0].tag].items
        setFilters(items)
        setType(items[0])
    }
    
    const onFilterPicked = (t) => {
        setType(t)
        setShowFilters(false)
    }
    const getMyOrders = () => {
        console.log("getMyLikedromOrders request")
        var params = getRequestParams()
        fetch(getRequestUrl() + "getMyLikedromOrders", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            console.log("getMyLikedromOrders = ", responseData)
            if (responseData.orders) {
                setHistory(responseData.orders)
            }  
            setHistoryLoading(false)
        })
        
    }
    const createOrder = () => {
        if (isLoading){
            return
        }
        if (props.count_coins < summ){
            setShowUpBalance(true)
            return
        }
        setShowUpBalance(false)
        setIsLoading(true)
        var params = getRequestParams()
        params.count_coins = summ
        params.service_likedrom_number = type.id
        params.social_network = type.sn
        params.order_url = url
        params.order_name = type.title
        if (type.is_auto_likes){
            params.posts = autoLikesCount
        }
        if (type.is_poll){
            params.poll_index = pollIndex
        }
        console.log("createLikedromOrder params = ", params)
        fetch(getRequestUrl() + "createLikedromOrder", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            setIsLoading(false)
            if (responseData.response > 0){
                window.location.href = '/social'
            } else {
                showToast(responseData.error)
            }
            
            
            console.log("createLikedromOrder response = ", responseData)
        })
        // count_coins - количество монет для заказа (обяз. парам)
        // service_likedrom_number - номер заказа лайкдрома из конфига (обяз. парам)
        // social_network - название соц сети из конфига (обяз. парам)
        // order_url - ссылка на серурс для продвижения (обяз. парам)
        // posts - количество постов только для автолайков в телеге, инсте или вк
        // poll_index - номер правильного ответа только для опросов в тг и вк
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 3000)
    }
    const autoLikeMultiplier = type.is_auto_likes ? autoLikesCount : 1
    const summ = type.price == undefined ? (count * priceItem * autoLikeMultiplier) : (count * type.price * priceItem * autoLikeMultiplier)
    const isUrlError = summ >= 0 && !(url && url != "" && isValidUrl(url)) && count >= type.min && count <= type.max
    const isMinMaxError = (url && url != "" && isValidUrl(url)) && summ >= 0 && (count < type.min || count > type.max)
    const isButtonEnabled = url && url != "" && isValidUrl(url) && count >= type.min && count <= type.max && summ >= 0
    return (
        
        <div className="promotion-page">
            <div className="display_block">
                <p className="h1 main__header-promotion">Продвижение соцсетей</p>
                <p className='social_pick_title'>Выберите соцсеть</p>
                <div className='social_container'>
                    <div className='social_left'>
                        <SocialPicker onItemPicked={onItemPicked} snItems={snItems}/>
                        
                        <div className='social_pick_title'>Что хотите накрутить?</div>
                        <div onClick={() => setShowFilters(!isShowFilters)} style={{width : '100%', position : 'relative'}} className="button_choose_app">
                            <div className="just_content w_100">
                                <p className="button_choose_text btn_filter_choose_text max_width_social">{type.title_price}</p>
                                <img alt='' style={isShowFilters ? arrowRotateStyle() : {}} className="drop_arrow" src={arrow_filter}/>
                            </div>
                            {isShowFilters && <FilterDropdown style={{width : 'calc(100% + 4px)'}} filters={filters} onFilterPicked={onFilterPicked}/>}
                        </div>

                        <div className='social_pick_title'>Ссылка на {type.name_object}</div>
                        <div style={isUrlError ? borderError : {}} className="button_choose_app social_w_100">
                            <input autoComplete="off" type="text" onChange={(event) => setUrl(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" placeholder={"Ссылка"} value={url}/>
                        </div>
                        <p className='social_url_description'>Фото, видео, пост</p>

                        {type.is_auto_likes && 
                            <div>
                                <div className='social_pick_title'>Сколько будущих публикаций?</div>
                                <div className='flex'>
                                    <MinusBtn count={autoLikesCount} setCount={setAutoLikesCount}/>
                                    <div style={{width : '160px', marginBottom : 0}} className="button_choose_app">
                                        <input autoComplete="off" type="number" onChange={(event) => setAutoLikesCount(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" value={autoLikesCount}/>
                                    </div>
                                    <PlusBtn  count={autoLikesCount} setCount={setAutoLikesCount}/>
                                    
                                </div>
                            </div>
                        }
                        {type.is_poll && 
                            <div>
                                <div className='social_pick_title'>Номер нужного ответа в опросе</div>
                                <div className='flex'>
                                    <MinusBtn count={pollIndex} setCount={setPollIndex}/>
                                    <div style={{width : '160px', marginBottom : 0}} className="button_choose_app">
                                        <input autoComplete="off" type="number" onChange={(event) => setPollIndex(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" value={pollIndex}/>
                                    </div>
                                    <PlusBtn  count={pollIndex} setCount={setPollIndex}/>
                                    
                                </div>
                            </div>
                        }
                        <div className='social_pick_title'>Количество</div>
                        <div className='flex'>
                            <MinusBtn count={count} setCount={setCount}/>
                            <div style={{width : '160px', marginBottom : 0}} className="button_choose_app">
                                <input autoComplete="off" type="number" onChange={(event) => setCount(event.target.value)} className="just_content social_w_100 button_choose_text btn_filter_choose_text max_width_social" value={count}/>
                            </div>
                            <PlusBtn count={count} setCount={setCount}/>
                            
                        </div>
                        <p style={isMinMaxError ? {color : 'rgba(255, 98, 98)'} : {}} className='social_url_description'>от {type.min} до {type.max}</p>
                        {type.conditions != undefined && <p className='social_conditions'>{type.conditions}</p>}
                        <div className='relative'>
                            <div onClick={createOrder} style={isButtonEnabled ? {} : {pointerEvents : 'none', opacity : '0.2'}} className='social_create_btn hover'>{isLoading ? <div style={{minHeight : '16px', marginTop : '4px', position : 'relative'}}><BtnLoader isWhite/></div> : <div>Создать заказ {summ} {declension(summ, "монета", "монеты", "монет")}</div>}</div>
                           {isShowUpBalance && 
                            <div className="tooltip__container tooltip-top rate-content__tooltip-wrapper tooltip-not_social">
                                <i style={props.arrowStyle ? props.arrowStyle : {top : 0}} className="tooltip__arrow rate-content__tooltip-arrow follow-tooltip-coins top_a_0"></i>
                                <p className="size_16">{(strings.NOT_ENOUGHT_COINS_TOOLTIP + " " + props.count_coins + " " + declension(props.count_coins, strings.COIN, strings.COIN_2, strings.COIN_5))}</p>
                                <p onClick={() =>  window.location.href = '/up-balance'} className="btn-get-money bot_m_0 hover">{strings.BUY_COINS}</p>
                            </div>}
                        </div>
                        <p className='social_url_description'>*Признана экстремистской организацией и запрещена на территории РФ.</p>
                    </div>
                    <div className='social_history_container'>
                        <UniversalTaskContainer/>
                        <SocialHistory history={history} historyLoading={historyLoading}/>
                    </div>
                    
                </div>
                
            </div>
            {toastText != "" && <Toast text={toastText}/>}
        </div>
    );
}

export default Social;


const UniversalTaskContainer = () => {
    return(
        // <div className='social_unversal'>
        //     Для ваших авторских заданий и продвижения других соцсетей используйте <a className='hover' target='_blank' href='/create_universal'>универсальные заказы </a>
        // </div>
        null
    )
}
const SocialHistory = ({ history, historyLoading }) => {
    return(
        <div className='social_history_border relative'>
            <div className='social_history_title'>История заказов</div>
            {history.map((item, index) => (
                <div className='social_history_item' index={index} key={index}>
                    <div className='flex'>
                        <img alt='' src={getSocialHistoryIcon(item.social_network)}/>
                        <div>
                            <p className='social_history_item_title'>{item.order_name}</p>
                            <div className='flex'>
                                <a rel="noopener noreferrer" href={item.order_url} target='_blank' className='social_item_url hover'>{item.order_url}</a>
                            </div>
                        </div>
                    </div>
                    <div style={item.done >= item.max ? {color: '#6edfa1'} : {}} className='social_item_progress'>{item.done}/{item.max}</div>
                </div>
            ))}
            {historyLoading && <Loader />}
            {(!historyLoading && history.length == 0) && <div className='social_empty'>Нет заказов</div>}
        </div>
    )
}
const MinusBtn = ({ count, setCount }) => {
    return(
        <div onClick={() => setCount(count - 1)} className='social_add_btn hover'>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12.5H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}
const PlusBtn = ({ count, setCount }) => {
    return(
        <div onClick={() => setCount(((count != undefined && count != "") ? parseInt(count) : 0) + 1)} style={{marginLeft : '11.5px'}} className='social_add_btn hover'>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5.5V19.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 12.5H19" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}



const Toast = ({ text }) => {
    if (text == ""){
        return (<div></div>)
    }
    return (
        <div className="profile_toast flex">
            {text}
        </div>
    )
}
const SocialPicker = ({ onItemPicked, snItems }) => {
   
    return(
        <div className="social_pick_flex">
            {snItems.map((item, index) => (
                <div onClick={() => onItemPicked(item)} style={item.isSelected ? {background: 'var(--lk-left-menu)'} : {}} className='social_pick_item hover' index={index} key={index}>
                    <img alt='' src={item.img}/>
                    <div style={item.isSelected ? {color : 'white'} : {}}>{item.title}</div>
                </div>
            ))}
        </div>
    )
}