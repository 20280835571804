import React from 'react';
import empty_user_insta from './insta_icons_tasks/empty_user_insta.svg'
import combo_comment from '../custom_icons/combo_comment.svg'
import combo_like from '../custom_icons/combo_like.svg'
import combo_view from '../custom_icons/combo_view.svg'
import chevron_next_insta_android from '../custom_icons/chevron_next.svg'
import BaseEarnTask from './BaseEarnTask';
import {getRequestParams, isFirstFollowerCompleted, setFirstFollowerCompleted} from './utils_android/CookieHelper'
import { ReCaptcha } from 'react-recaptcha-v3'
import { getTimestampToken, getRequestUrl, getHttpParams } from '../../utils/Utils';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
var strings = getLocalizedStrings()
class SubscriptionTask extends BaseEarnTask {
    constructor(props){
        super(props)
        this.state = {
            currentDescription : "",
            isUpdateCaptcha : false,
            isShowError : false,
            isShowUnfollowTooltip : false,
            isImgError : false,
            type : 2
           
        }
        this.startTaskTime = -1
        window.extraFollow = this
        this.toogleImgError = this.toogleImgError.bind(this);
    }
    toogleImgError(){
        this.setState({isImgError : true})
    }
    onResume(){
        this.setState({isUpdateCaptcha : true})
        console.log("onResume 1")
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})
        console.log("verifyCallback 1 = " + recaptchaToken)
        if (this.startTaskTime != -1){
            
            var currentTime = new Date().getTime()
            if ((currentTime - 300) >= this.startTaskTime){
                console.log("onResume true")
                this.props.showLoader()
                this.instaFollow(recaptchaToken)
            }
        }
    }
    
    doTask = () => {
        this.startTaskTime = new Date().getTime()
        this.openUrl(this.props.currentExtraOrder.insta_url, 0)
    }
    instaFollow = (recaptchaToken) => {
        this.setState({isShowError : false})
        var params = getRequestParams();
        params.timestamp = recaptchaToken
        params.user_id = this.props.currentExtraOrder.order.user_id;
        params._id = this.props.currentExtraOrder.order._id;
        params.user_name = this.props.currentExtraOrder.userInfo ? this.props.currentExtraOrder.userInfo.username : this.props.currentExtraOrder.order.user_name
        console.log("instaFollow params = " + JSON.stringify(params))
        fetch(getRequestUrl() + "instaFollow", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            if (responseData.response == 1 || responseData.response == 2) {
                this.props.loadNextOrder()
                this.props.taskCompleted()
            } else {
                this.setState({isShowError : true})
                this.errorTask()
            }
            this.startTaskTime = -1
            if (responseData.penalty != undefined){
                this.props.showFinedDialog(responseData.penalty)
            }
            console.log("instaFollow" + JSON.stringify(responseData))
        })
    }
    skip = () => {
        this.skipOrder(false, this.props.currentExtraOrder.order._id)
    }
    
    render() {
        return (
            <div className="rate-content__item">
                    <div className="followers_container_appeal">
                        <p className="block_account">{strings.BLOCK_ACC_1}</p>
                        <img width="64" height="64" className="placeholder_task icon_64"
                            src={empty_user_insta} alt=""/>
                        <img style={this.state.isImgError ? {opacity : '0'} : {}} onError={this.toogleImgError} className="accounts__photo"
                            src={this.props.currentExtraOrder.userInfo ? this.props.currentExtraOrder.userInfo.profile_pic_url : this.props.currentExtraOrder.order.photo_url}
                            alt="" width="104" height="104"/>
                        <h4 className="accounts__login">
                            {this.props.currentExtraOrder.userInfo ? this.props.currentExtraOrder.userInfo.username : this.props.currentExtraOrder.order.user_name}
                        </h4>
                        <img onClick={this.skip} src={chevron_next_insta_android} className="arrows__item arrows__item_next arrow_item_follower hover"></img>
                    </div>

                    
                    <div className="rate-content__actions">
                        {this.state.isShowError && <p className="block_account">{strings.ERROR_LIKE_1}<br/>{strings.ERROR_LIKE_2}</p>}
                        
                        <div onClick={this.doTask} className="btn-follow btn_combo top_10">
                            <img className="img_subscription" src={this.state.type == 0 ? combo_like : this.state.type == 1 ? combo_comment : combo_view} alt=""/>
                            <p className="combo_text">{this.state.type == 0 ? strings.COMBO_LIKE : this.state.type == 1 ? strings.COMBO_COMMENT : strings.COMBO_VIEW}</p>
                        </div>
                        
                        <div className="coins_rub_lashka rub_plashka">
                            <span className="subscribe_follow_reward right_4">+{this.props.reward} {strings.COIN_2}</span>
                            <span className="subscribe_follow_reward left_4">+{this.props.currentExtraOrder.earn_money}₽</span>
                        </div>
                    </div>
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey={getTimestampToken()}
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    } 
                </div>
        );
    }
}
export default SubscriptionTask;
