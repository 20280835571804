/* eslint-disable eqeqeq */
import { getIsTagsEuro } from "../../utils/Utils"

export const calcCoins = (enteredCoins) => {
    var standartPrice = Math.round(enteredCoins / 3)
    if (enteredCoins >= 100) {
      standartPrice = Math.round(enteredCoins / 4)
    }
    if (enteredCoins >= 500) {
      standartPrice = Math.round(enteredCoins / 5.05)
    }
    if (enteredCoins >= 1000) {
      standartPrice = Math.round(enteredCoins / 6.3)
    }
    if (enteredCoins >= 5000) {
      standartPrice = Math.round(enteredCoins / 7.16)
    }
    if (enteredCoins >= 10000) {
      standartPrice = Math.round(enteredCoins / 8)
    }
    return standartPrice
}
export const getTagsEuroPrices = (enteredCoins) => {
    var standartPrice = 30
    if (enteredCoins == 100) {
        standartPrice = 30
    }
    if (enteredCoins == 500) {
      standartPrice = 129
    }
    if (enteredCoins == 1000) {
      standartPrice = 239
    }
    if (enteredCoins == 5000) {
      standartPrice = 999
    }
    if (enteredCoins == 10000) {
      standartPrice = 1790
    }
    if (enteredCoins == 50000) {
      standartPrice = 6490
    }
    return standartPrice
}
export const calcStandartPrice = (enteredCoins) => {
    var standartPrice = Math.round(enteredCoins / 3)
    const isTagsEuro = getIsTagsEuro()
    if (isTagsEuro){
        standartPrice = getTagsEuroPrices(enteredCoins)
    }
    return standartPrice
}