/* eslint-disable eqeqeq */
import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import chevron_next from '../custom_icons/chevron_next.svg'
import { getUserId, getInstaLogin, getRequestParams } from '../insta_custom_views/utils_android/CookieHelper';
import { declension, SHA256, getRequestUrl, getHttpParams, PaymentType, getIsTagsEuro } from '../../utils/Utils';
import { getUrlParameter } from '../../utils/UrlHelperAndroid';
import PaymentTypePicker from './PaymentTypePicker';
var strings = getLocalizedStrings()

class ConfirmAppPayment extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            fromBalanceChecked : false,
            email : (localStorage.getItem('email_user') == undefined || localStorage.getItem('email_user') == null || localStorage.getItem('email_user') == "") ? "" : localStorage.getItem('email_user'),
            emailError : false,
            paymentType : PaymentType.card
        }
        this.hideConfirm = this.hideConfirm.bind(this);
        this.toogleFromMoney = this.toogleFromMoney.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        window.scrollTo(0,0);
    }
    preparePurchase = () => {
        if (this.state.emailError){
            this.setState({emailError : false})
        }
        if (this.state.email == "" || this.state.email == undefined || !this.state.email.includes("@")){
            this.setState({emailError : true})
            return
        }
        if (getUrlParameter('user_id') == undefined || getUrlParameter('user_id') == null || getUrlParameter('user_id') == ""){
            return
        }
        if (this.props.price != undefined && this.props.price > 0) {
            const price =  this.props.price
            const descriptionPay = strings.BUY_COINS_UNITPAY + " " + this.props.coins + " " + declension(this.props.coins, strings.COIN, strings.COIN_2, strings.COIN_5)
            const id = getUrlParameter('user_id')
            const app = this.getPaySuffics(getUrlParameter('bundle'))
            // (getUrlParameter('bundle') == "com.kotdagrel.tagseuro") ? "_app" : "_app2"
            const userID = id + app
            var params = getRequestParams()
            params.price = (price * 100)
            params.user_id = userID
            console.log("preparePurchase params = " + JSON.stringify(params))
            
            if (this.state.paymentType == PaymentType.card){
                fetch(getRequestUrl() + "preparePurchase", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1) {
                        this.createTinkoffPurchase(price, userID, descriptionPay, responseData._id)        
                    }
                    console.log("preparePurchase = " + JSON.stringify(responseData))
                })
            } else if (this.state.paymentType == PaymentType.usdt) {
                params.amount = price
                params.email = this.state.email
                fetch(getRequestUrl() + "createInvoiceCryptomusUpsocial", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response > 0) {
                        localStorage.setItem('email_user', this.state.email)
                        window.location.href = responseData.result.result.url

                    }
                })
            }
        }
    }
    getPaySuffics = (bundle) => {
        if (bundle.includes("com.kotdagrel.tagseuro")){
            //our app insta
            return "_app"
        } else if (bundle.includes("app.ios.1")){
            //our app insta
            return "_app.ios.1"
        } else if (bundle.includes("com.hashtags.promotok")){
            //our tiktok
            return "_tiktok"
        } else if (bundle.includes("com.oatmeal.cookies")){
            //Vitya tiktok
            return "_tiktok2"
        } else if (bundle.includes("com.vve.instaprogress") || bundle.includes("ru.vde.instatagrelease") || bundle.includes("com.planner.instatools")){
            //Vitya insta vve
            return "_app2"
        } else {
            return "_app"
        }
    }
    createTinkoffPurchase = (price, userID, descriptionPay, orderId) => {
        const tinkoffData = {
            TerminalKey : "1628084040223",
            Amount : (price * 100),
            OrderId : orderId,
            Description : descriptionPay,
            DATA : {
                Email : this.state.email,
                UserID : userID
            },
            Receipt : {
                Email : this.state.email,
                Taxation : "usn_income",
                Items : [{
                    Name : descriptionPay,
                    Quantity : 1,
                    Amount : (price * 100),
                    Price : (price * 100),
                    Tax : "none"
                }]
            },
            SuccessURL : "https://instarlike.com/payment_app?success=true",
            FailURL : "https://instarlike.com/payment_app?success=false"
        }
        fetch("https://securepay.tinkoff.ru/v2/Init", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tinkoffData)
        }).then((response) => response.json())
        .then((responseData) => {
            if (responseData.Success){
                localStorage.setItem('email_user', this.state.email)
                window.location.href = responseData.PaymentURL
            } else {
                this.setState({emailError : true})
            }
            console.log("responseData = " + JSON.stringify(responseData))
        })
    }
    emailChanged(event){
        if(event){
            var value = event.target.value
            this.setState({email : value})
            if (this.state.emailError){
                this.setState({emailError : false})
            }
        }
    }
    hideConfirm(){
        this.props.toogleConfirm()
    }
    toogleFromMoney(){
        this.setState({fromBalanceChecked : !this.state.fromBalanceChecked})
    }
    
    render(){
            const price = this.props.price
            const isTagsEuro = getIsTagsEuro()
            return (
                    <div className="confirm_container">
                        <div className="flex_confirm">
                            <img onClick={this.hideConfirm} className="back_confirm_icon hover" src={chevron_next}/>
                            <h2 className="h1">{strings.CONFIRM_ORDER}</h2>
                        </div>
                        <div className="confirm_paddings">
                            <div className="confirm_prices_container">
                                <div className="flex_confirm just_space">
                                    <p className="confirm_text">{strings.CONFIRM_ORDER_BUY}</p>
                                    <p className="confirm_text confirm_bold">{this.props.coins} {declension(this.props.coins, strings.COIN, strings.COIN_2, strings.COIN_5)}</p>
                                </div>

                                <div className="flex_confirm just_space">
                                    <p className="confirm_text">{strings.CONFIRM_ORDER_PRICE}</p>
                                    <div className="flex_confirm">
                                        <p className="line_throught">{this.props.fakePrice}₽</p>
                                        <b className="confirm_text confirm_bold confirm_price">{price}₽</b>
                                    </div>
                                </div>
                                <p style={{marginTop : "20px", marginBottom : "0px"}} className="from_balance_text">{strings.CONFIRM_EMAIL}</p>
                                <input style={this.state.emailError ? {border: "1px solid #E1044B"} : {}} value={this.state.email} onChange={this.emailChanged} placeholder={strings.EMAIL_PLACEHOLDER} className="input add-subscribe-form__input input-another-container_shop" name="Count"/>
                                {this.state.emailError && <p style={{marginTop : "0px", marginBottom : "0px", color : "#E1044B"}} className="from_balance_text">{strings.EMAIL_ERROR}</p>}
                            </div>
                        </div>
                        
                        <PaymentTypePicker price={price} paymentType={this.state.paymentType} setPaymentType={(type) => this.setState({paymentType : type})}/>

                        <div onClick={this.preparePurchase} style={isTagsEuro ? {bottom : 'auto', left : '16px', background : '#A1DFFA', color : 'black'} : {bottom : 'auto', left : '16px'}} className="confirm_buy_btn hover">{strings.CONFIRM_ORDER_BUY_BTN}<strong className="left_m_8">{price}₽</strong></div>
                        
                    </div>
                    
            ); 
        
        
    }
}
export default ConfirmAppPayment;
