import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import '../tooltips/tooltips.css'

var strings = getLocalizedStrings()

class InstaProblemsTooltip extends React.Component {
    constructor(props){
        super(props)
    }
    
    render(){
      
            return (
                <div className="session_banner top_m">
                    <div className="insta_problems_tooltip">
                        <p className="session_banner_title">Наблюдаются сбои в работе</p>
                        <p className="session_banner_text">Сейчас происходят изменения на стороне Instagram. Из-за этого наблюдаются сбои в накрутке: фактическое количество заказанных лайков/подписчиков/комментариев может отличаться.</p>
                    </div>
                </div>
            ); 
        
        
    }
}
export default InstaProblemsTooltip;
