import React from 'react';
import { addLogin, saveInstaCookie, getLogin } from '../custom_views/insta_custom_views/utils_android/CookieHelper';
import { getLocalizedStrings } from '../utils/LocalizedStrings';

import { getProgress, videoIconStyle, isStartCampaign, isFinishedCampaign, isInprocessCampaign, commentsOffStyle, getProgressColorLike, getProgressColorComment, videoViewsStyle, getProgressColorView, getSource } from '../utils/PhotoItemHelper';
import NotEnoughtCoins from '../custom_views/tooltips/NotEnoughtCoins';
import LanscapeOrder from '../custom_views/promotion/LandscapeOrder';
import MobileOrder from '../custom_views/promotion/MobileOrder';
import error_img from '../custom_views/custom_icons/error_img.svg'
var strings = getLocalizedStrings()
var canvas;
var img;
class PhotoItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            notEnoughtCoins : "",
            isShowTooltip : false,
            isShowNotEnought : false,
            isMobile : (window.screen.width <= 768 ? true : false),
            src : ""
        }
       
    }
    toogleTooltip = () => {
        this.setState({isShowTooltip : !this.state.isShowTooltip})
    }
    // loadImgAsBase64 = (url, callback) => {
    //     canvas = document.createElement('CANVAS');
    //     img = document.createElement('img');
    //     img.setAttribute('crossorigin', 'anonymous');
    //     img.src = 'https://cors-anywhere.herokuapp.com/' + url;
      
    //     img.onload = this.onLoadedImg
    //   }
    // onLoadedImg = () => {
    //     canvas.height = img.height;
    //     canvas.width = img.width;
    //     let context = canvas.getContext('2d');
    //     context.drawImage(img, 0, 0);
    //     let dataURL = canvas.toDataURL('image/png');
    //     canvas = null;
    //     //callback(dataURL);
    //     this.setState({src : dataURL})
    //     console.log("onLoadedImg = " + dataURL)
    // }
    // componentDidMount(){
    //    // this.loadImgAsBase64(getSource(this.props.photo))
    //     this.loadImgAsBase64("https://scontent-lcy1-1.cdninstagram.com/v/t51.2885-19/s150x150/31748590_142568999805180_1164843848489238528_n.jpg?tp=1&_nc_ht=scontent-lcy1-1.cdninstagram.com&_nc_ohc=UKxD-Hd3QusAX_pwCE3&edm=ABmJApABAAAA&ccb=7-4&oh=0977b410240a77c6cc02903ffe9ebc77&oe=60AB9171&_nc_sid=6136e7")
    // }

    render(){
        
            return (
                <div style={(!this.state.isMobile && this.state.isShowTooltip) ? {height : '800px'} : {}} className="account-content__item">
                    <div className="mobile_click hover_box" onClick={this.toogleTooltip}>
                        <i style={videoIconStyle(this.props.photo)} className="account-content__video-icon"></i>
                        
                        <img className="account-content__photo" src={getSource(this.props.photo)}/>
                        {/* {(getLogin() == "bums3230" || getLogin() == "zhenekvv") ? 
                         <img className="account-content__photo" src={getSource(this.props.photo)}/>
                        : <img className="account-content__photo" src={error_img}/>} */}
                       
                        {/* <img className="account-content__photo" src={error_img}/> */}
                        <div className="account-content__social-actions">
                            <span className="account-content__like">{this.props.photo.like_count}</span>
                            <span className="account-content__comment">{this.props.photo.comment_count}</span>
                            <span className="account-content__warn">0</span>
                        </div>
                        <div style={isStartCampaign(this.props.photo)} className="account-content__progress account-content__progress_promo">{strings.PROMOTE_TASK}</div>
                        <div style={isFinishedCampaign(this.props.photo)} className="account-content__progress account-content__progress_done">{strings.COMPLETED_TASK}</div>
                        <div style={isInprocessCampaign(this.props.photo)} className="account-content__progress">{strings.IN_PROGRESS_TASK}</div>
                    </div>
                   
                    {(this.state.isShowTooltip && !this.state.isMobile) && <LanscapeOrder sessionError={this.props.sessionError} index={this.props.index} orderCreated={this.props.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} toogleTooltip={this.toogleTooltip} photo={this.props.photo}/>}
                    {(this.state.isShowTooltip && this.state.isMobile) && <MobileOrder sessionError={this.props.sessionError} index={this.props.index} orderCreated={this.props.orderCreated} updateMoney={this.props.updateMoney} count_money_r={this.props.count_money_r} count_coins={this.props.count_coins} toogleTooltip={this.toogleTooltip} photo={this.props.photo}/>}
                    {/* <MobileOrder index={this.props.index} orderCreated={this.props.orderCreated} updateMoney={this.props.updateMoney} count_coins={this.props.count_coins} toogleTooltip={this.toogleTooltip} photo={this.props.photo}/> */}
                </div>
            ); 
        
        
    }
}
export default PhotoItem;
