import React from 'react';
import {getRequestParams, setShownHomeScreen, isShownHomeScreen, isFirstTaskCompleted, setFirstCommentCompleted, setFirstTaskCompleted, getLogin} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import ExtraComment from './custom_views/insta_custom_views/ExtraComment';
import BaseEarnTask from './custom_views/insta_custom_views/BaseEarnTask';
import ExtraFollow from './custom_views/insta_custom_views/ExtraFollow';
import ExtraView from './custom_views/insta_custom_views/ExtraView';
import PhotoTask from './custom_views/insta_custom_views/PhotoTask';
import LoaderEarn from './custom_views/insta_custom_views/utils_android/LoaderEarnAndroid';
import { getRequestUrl, getHttpParams, getTimestampToken } from './utils/Utils';
import { getLocalizedStrings } from './utils/LocalizedStrings';
import  help_hover  from '../pages_android/custom_views/custom_icons/help.png';
import EarnQuestionTooltip from './custom_views/tooltips/EarnQuestionTooltip';
import IosHomeScreen from './custom_views/tooltips/IosHomeScreen';
import AndroidHomeScreen from './custom_views/tooltips/AndroidHomeScreen';
import { getUrlParameter } from './utils/UrlHelperAndroid';
import DoFirstTask from './custom_views/tooltips/DoFirstTask';
import SuccessSessionTooltip from './custom_views/tooltips/SuccessSessionTooltip';
import FineDialog from './custom_views/insta_custom_views/FineDialog';
import CustomTaskLink from './custom_views/insta_custom_views/CustomTaskLink';
import CustomTaskWords from './custom_views/insta_custom_views/CustomTaskWords';
import CustomTaskTelegram from './custom_views/insta_custom_views/CustomTaskTelegram';
import PhotoScreenTask from './custom_views/insta_custom_views/PhotoScreenTask';
import EmptyTaskBonus from './custom_views/insta_custom_views/EmptyTaskBonus';
import SubscriptionTask from './custom_views/insta_custom_views/SubscriptionTask';
import { loadReCaptcha } from 'react-recaptcha-v3'
var strings = getLocalizedStrings()
var taskCounter = 0
class Earn extends BaseEarnTask {
    constructor(props){
        super(props)
        var isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; 
        var isShownHome = isShownHomeScreen()
        if (!isShownHome){
            setShownHomeScreen()
        }
        this.state = {
            currentDescription : "",
            isLoading : true,
            currentExtraOrder : {},
            currentPhotoTask : {},
            isCurrentExtra : true,
            currentType : -1,
            extraOrdersCount : 0,
            isShowEmpty : false,
           // isUpdateCaptcha : true,
           // timestamp : "",
            rewardFollow : -2,
            rewardPhoto : -1,
            isShowEarnQuestionTooltip : false,
            isShowScreenTooltip : !isShownHome,
            isIos : isIos,
            isAndroid : isAndroid,
            isShowFirstTask : false,
            isShowSuccesBanner : false,
            isShowFineDialog : false,
            fineCoinsCount : 0,
            isShowScreens : false
        }
        this.toogleSceenTooltip = this.toogleSceenTooltip.bind(this);
        this.toogleDoFirstTask = this.toogleDoFirstTask.bind(this);
        this.toogleSuccesBanner = this.toogleSuccesBanner.bind(this);
        this.taskCompleted = this.taskCompleted.bind(this);
        this.showFirsTaskTooltip = this.showFirsTaskTooltip.bind(this);
        this.toogleFineDialog = this.toogleFineDialog.bind(this);

    }
    componentDidMount(){
        this.getPhotoTask()
        //this.getExtraOrder()
        this.getPrices()
        this.checkSession(!this.state.isShowScreenTooltip)
        loadReCaptcha(getTimestampToken());

        //  window.addEventListener('beforeinstallprompt', (e) => {
        //     alert("beforeinstallprompt log")
        //     e.preventDefault()
        // })
    }
    checkSession = (isShowTooltip) => {
        var session = getUrlParameter("session_banner")
        if (isShowTooltip){
            if (session == "1"){
                this.setState({isShowFirstTask : true})
            }
        }
    }
    
    // componentDidUpdate(prevProps){
    //     if (this.props != prevProps && this.props.timestamp != "" && this.state.timestamp == ""){
    //         this.setState({timestamp : this.props.timestamp})
    //     }
    // }
    showLoader = () => {
        this.setState({isLoading : true, isUpdateCaptcha : true})
    }
    hideLoader = () => {
        this.setState({isLoading : false, isUpdateCaptcha : false})
    }
    loadNextOrder = (isLoadPhoto) => {
        if (isLoadPhoto){
            this.getPhotoTask()
        } else {
            this.getExtraOrder()
        }
    }
    getPrices = () => {
        var params = getRequestParams();
        fetch(getRequestUrl() + "getRewardAndPrice", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    this.setState({rewardPhoto : responseData.REWARD_TASK, rewardFollow : responseData.REWARD_FOLLOWER})
                    console.log("getPhotoTask = " + JSON.stringify(responseData));
                })  
    
    }
    getExtraOrder = () => {
        this.setState({isShowScreens : false})
        if (this.isShowCustomTask()){
            return
        }
        
        this.showLoader();
        var params = getRequestParams();
        //params.timestamp = this.state.timestamp
        console.log("params getExtraOrder = " + JSON.stringify(params));

        fetch(getRequestUrl() + "getExtraOrders", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.order) {
                        this.setState({
                             currentExtraOrder : responseData,
                             isCurrentExtra : true,
                             isLoading : false,
                             //isUpdateCaptcha : false,
                             currentType : responseData.order.type
                            })
                       
                        switch (responseData.order.type) {
        
                            case 0:
                                //followers
                                this.setState({currentDescription : this.FOLLOW_PHOTO_TASK_DESC})
                                if (responseData.order.user_name == undefined || responseData.order.user_name == "undefined") {
                                    this.skipOrder(true);
                                }
                                break;
                            case 1:
                                //views
                                this.setState({currentDescription : this.VIDEO_TASK_DESCRIPTION})
                                break;
                            case 2:
                                //comments
                                this.setState({currentDescription : this.COMMENT_PHOTO_TASK_DESC})
                                break;
                            default:
        
        
                        }
                      this.props.setMoney(responseData.count_money_r, responseData.count_coins)
                    } else {
                        this.setState({currentExtraOrder : {}, isCurrentExtra : false})
                        //this.getPhotoTask()
                        this.showNoTasksView()
                    }
        
        
                    console.log("extra order = " + JSON.stringify(responseData));
                })         
    }
    getPhotoTask = () => {
        if (this.isShowCustomTask()){
            this.setState({isShowScreens : false})
            return
        }
        this.showLoader();
        var params = getRequestParams();
        //params.timestamp = this.state.timestamp
        fetch(getRequestUrl() + "getPhotoTask", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.photo) {
                        this.setState({currentPhotoTask : responseData, isCurrentExtra: false, currentDescription : this.LIKE_PHOTO_TASK_DESC, currentType : 3})
                        if (taskCounter > 2 && responseData.photo.is_photo){
                            //this.setState({isShowScreens : (Math.random() <= 0.1)})
                        }
                    } else {
                        this.getExtraOrder();
                    }
                    this.props.setMoney(responseData.count_money_r, responseData.user_coins)
                    this.setState({isLoading : false, isUpdateCaptcha : false})
                    console.log("getPhotoTask = " + JSON.stringify(responseData));
                })         
    }
    isShowCustomTask = () => {
        taskCounter += 1
        var isShownTG = localStorage.getItem('isShownTG')
        var isShareTask = localStorage.getItem('isShareTask')
        var isShownEarnTask = localStorage.getItem('isShownEarnTask')
        if (taskCounter == 5 && (isShownTG == null || isShownTG == undefined)){
            localStorage.setItem('isShownTG', "shown")
            this.setState({currentType : 6, isLoading : false, currentDescription : strings.EARN_LINK_TITLE_DESC})
            return true
        } 
        // else if (taskCounter == 10 && (isShareTask == null || isShareTask == undefined)){
        //     localStorage.setItem('isShareTask', "shown")
        //     this.setState({currentType : 4, isLoading : false, currentDescription : strings.SHARE_LINK_TITLE_DESC})
        //     return true
        // } 
        else if (taskCounter == 10 && (isShownEarnTask == null || isShownEarnTask == undefined)){
            localStorage.setItem('isShownEarnTask', "shown")
            this.setState({currentType : 5, isLoading : false, currentDescription : strings.EARN_LINK_TITLE_DESC})
            return true
        } else {
            return false
        }  
    }
    showNoTasksView = () => {
        console.log("showNoTasksView")
        this.setState({isShowEmpty : true, isLoading : false, currentPhotoTask : {}})
    }   
    onOrderSkipped = () => {
        var skipOrders = this.state.extraOrdersCount + 1
        this.setState({extraOrdersCount : skipOrders})
        console.log("skipOrders count = " + skipOrders)
        return skipOrders
    }
    toogleFineDialog(){
        this.setState({isShowFineDialog : !this.state.isShowFineDialog})
    }
    showFinedDialog = (coinsCount) => {
        this.setState({isShowFineDialog : true, fineCoinsCount : coinsCount})
    }
    toogleEarnQuestion = () => {
        this.setState({isShowEarnQuestionTooltip : !this.state.isShowEarnQuestionTooltip})
    }
    toogleSceenTooltip(){
        this.setState({isShowScreenTooltip : !this.state.isShowScreenTooltip})
        this.checkSession(true)
    }
    toogleDoFirstTask(){
        this.setState({isShowFirstTask : !this.state.isShowFirstTask})
    }
    toogleSuccesBanner(){
        this.setState({isShowSuccesBanner : !this.state.isShowSuccesBanner})
    }
    showFirsTaskTooltip(){
        this.checkSession(true)
    }
    taskCompleted(){
        if (!isFirstTaskCompleted()){
            this.setState({isShowSuccesBanner : true})
            setFirstTaskCompleted()
        }
    }
    render() {
        return (
            <section className="earn-money_section">
                <div className="titile_cointainer flex">
                    <p className="main_title">{strings.MENU_2}</p>
                    {/* {this.state.isShowFirstTask && <DoFirstTask toogleDoFirstTask={this.toogleDoFirstTask}/>}
                    {this.state.isShowSuccesBanner && <SuccessSessionTooltip toogleSuccesBanner={this.toogleSuccesBanner}/>} */}
                    <div onClick={this.toogleEarnQuestion} className="earn_help hover img_quest">
                        <img src={help_hover} className="img_help"/>
                    </div>
                </div>
                
                <div className="line_earn"/>
                
                {this.state.isShowScreens ?
                    <p className="task_text">{strings.SCREEN_DESCRIPTION} <span style={{fontFamily : 'OpenSans-Bold'}}>{strings.SCREEN_DESCRIPTION_2}</span> {strings.SCREEN_DESCRIPTION_3}</p>
                     : 
                     <p className="task_text">{this.state.isShowEmpty ? "" : this.state.currentDescription}</p>
                }
                {this.state.currentExtraOrder.order && this.state.currentType == 2 && <ExtraComment taskCompleted={this.taskCompleted} reward={this.state.rewardFollow} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.currentExtraOrder.order && this.state.currentType == 0 && <ExtraFollow showFinedDialog={this.showFinedDialog} taskCompleted={this.taskCompleted} reward={this.state.rewardFollow} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.currentExtraOrder.order && this.state.currentType == 1 && <ExtraView reward={this.state.rewardFollow} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {/* {this.state.currentPhotoTask.photo && this.state.currentType == 3 && (getLogin() == "zama5470" || getLogin() == "bums3230") ? <PhotoScreenTask taskCompleted={this.taskCompleted} reward={this.state.rewardPhoto} timestamp={this.state.timestamp} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/> : */}
                {this.state.currentPhotoTask.photo && this.state.currentType == 3 && this.state.isShowScreens ? <PhotoScreenTask taskCompleted={this.taskCompleted} reward={this.state.rewardPhoto} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/> :
                this.state.currentPhotoTask.photo && this.state.currentType == 3 && <PhotoTask taskCompleted={this.taskCompleted} reward={this.state.rewardPhoto} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>}
                {this.state.currentType == 4 && <CustomTaskWords loadNextTask={this.getPhotoTask}/>}
                {this.state.currentType == 5 && <CustomTaskLink loadNextTask={this.getPhotoTask}/>}
                {this.state.currentType == 6 && <CustomTaskTelegram loadNextTask={this.getPhotoTask}/>}
                {/* {this.state.currentExtraOrder.order && <SubscriptionTask showFinedDialog={this.showFinedDialog} taskCompleted={this.taskCompleted} reward={this.state.rewardFollow} timestamp={this.state.timestamp} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentExtraOrder={this.state.currentExtraOrder} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/>} */}
                {this.state.isShowEmpty && <EmptyTaskBonus showLoader={this.showLoader} loadNextOrder={this.loadNextOrder}/>}
                {/* {this.state.isShowEmpty && <EmptyTask showLoader={this.showLoader} loadNextOrder={this.loadNextOrder}/>} */}
                {/* <PhotoScreenTask taskCompleted={this.taskCompleted} reward={this.state.rewardPhoto} timestamp={this.state.timestamp} showLoader={this.showLoader} loadNextOrder={this.loadNextOrder} isCurrentExtra={this.state.isCurrentExtra} currentPhotoTask={this.state.currentPhotoTask} hideLoader={this.hideLoader} onOrderSkipped={this.onOrderSkipped} extraOrdersCount={this.state.extraOrdersCount}/> */}
                {this.state.isLoading && <LoaderEarn/>}
                {this.state.isShowEarnQuestionTooltip && <EarnQuestionTooltip toogleEarnQuestion={this.toogleEarnQuestion}/>}
                {this.state.isShowScreenTooltip && (this.state.isIos ? <IosHomeScreen toogleSceenTooltip={this.toogleSceenTooltip}/> : this.state.isAndroid ?  <AndroidHomeScreen showFirsTaskTooltip={this.showFirsTaskTooltip} toogleSceenTooltip={this.toogleSceenTooltip}/> : <div/>)}
                {this.state.isShowFineDialog && <FineDialog toogleFineDialog={this.toogleFineDialog} coinsCount={this.state.fineCoinsCount}/>}

                {/* {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey={getTimestampToken()}
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    }  */}
                  
            </section>
        );
    }
}
export default Earn;