import React from 'react';
import { getLocalizedStrings } from '../../utils/LocalizedStrings';
import { getProgress, videoIconStyle, isStartCampaign, isFinishedCampaign, isInprocessCampaign, getMobileDisplayStyle,getCountMoneyTask,commentsOffStyle, getProgressColorLike, getProgressColorComment, videoViewsStyle, getProgressColorView, getSource, getEnabledBtnStyle } from '../../utils/PhotoItemHelper';
import { declension } from '../../utils/Utils';
import NotEnoughtCoins from '../tooltips/NotEnoughtCoins';
import close from '../custom_icons/close.svg'
import rubl_icon from '../custom_icons/rubl_icon.svg'
import cart from '../custom_icons/cart.svg'
import coins_icon_order from '../custom_icons/coins_icon_order.svg'
import OrderBase from './OrderBase';
import BtnLoader from '../loaders/BtnLoader';
import SessionErrorOrder from '../tooltips/SessionErrorOrder';
import error_img from '../custom_icons/error_img.svg'
var strings = getLocalizedStrings()

class MobileOrder extends OrderBase {
    constructor(props){
        super(props)
        this.workClicked = this.workClicked.bind(this)
        this.buyCoinsClicked = this.buyCoinsClicked.bind(this)
        
    }
    workClicked(){
        window.location.href = '/work'
    }
    buyCoinsClicked(){
        window.location.href = '/up-balance'
    }
    render(){
            const isStories = (this.props.photo.id.includes("_&&&_storie"))
            return (
                <div className="mobile-tooltip-new transformable-profiles">
                    <header className="mobile-tooltip__header">
                        <i onClick={this.props.toogleTooltip} className="mobile-tooltip__back"></i>
                        <a className="header__coins" href="#">
                            {/* <img onClick={this.workClicked} className="coins__icon coins__icon_rubles" src={rubl_icon}/>
                            <span onClick={this.workClicked} className="coins__value coins__value_rubles ng-binding">{parseFloat(this.props.count_money_r).toFixed(2)}</span> */}
                            <img onClick={this.buyCoinsClicked} className="coins__icon left_m16" src={coins_icon_order}/>
                            <div onClick={this.buyCoinsClicked} className="coins_block">
                                <span className="coins__value">{this.props.count_coins}<br/>{declension(this.props.count_coins, strings.COIN, strings.COIN_2, strings.COIN_5)}</span>
                                <img className="coins_up_image" src={cart}/>
                            </div>
                        </a>
                    </header>
                    <img className="mobile-tooltip__photo" src={getSource(this.props.photo)} alt="Photo"/>
                    {/* <img className="mobile-tooltip__photo" src={error_img} alt="Photo"/> */}
                    <div className="mobile-tooltip__total">
                        {!isStories && <div>
                            <h4 className="mobile-tooltip__heading">{strings.ALL_IN_PHOTO}</h4>
                            <div className="mobile-tooltip__container">
                                <div className="mobile-tooltip__block_2">
                                    <p className="tooltip_weight">{this.props.photo.like_count}</p>
                                    <p className="tooltip_mobile_likes">{declension(this.props.photo.like_count, strings.LIKE, strings.LIKE_2, strings.LIKE_5)}</p>
                                </div>
                                <div className="mobile-tooltip__block_2">
                                    <p className="tooltip_weight">{this.props.photo.comment_count}</p>
                                    <p className="tooltip_mobile_likes">{declension(this.props.photo.comment_count, strings.COMMENT, strings.COMMENT_2, strings.COMMENT_5)}</p>
                                </div>
                            </div>
                        </div>}
                        <div style={commentsOffStyle(this.props.photo)} className="private-profile">{strings.COMMENTS_OFF}</div>
                        <h4 className="mobile-tooltip__heading">{strings.MADE_ORDERED}</h4>
                        <div className="mobile-tooltip__container">
                            <div style={getMobileDisplayStyle(this.props.photo)} className="mobile-tooltip__block mobile-tooltip__block_ordered">
                                <p style={getProgressColorLike(this.props.photo)} className="tooltip_weight">{getProgress(this.props.photo.our_info_photo)}</p>
                                <p className="tooltip_mobile_likes">{declension(getCountMoneyTask(this.props.photo.our_info_photo), strings.LIKE, strings.LIKE_2, strings.LIKE_5)}</p>
                            </div>
                            <div style={getMobileDisplayStyle(this.props.photo)} className="mobile-tooltip__block mobile-tooltip__block_done">
                                <p style={getProgressColorComment(this.props.photo)} className="tooltip_weight">{getProgress(this.props.photo.our_info_extra_2)}</p>
                                <p className="tooltip_mobile_likes">{declension(getCountMoneyTask(this.props.photo.our_info_extra_2), strings.COMMENT, strings.COMMENT_2, strings.COMMENT_5)}</p>
                            </div>
                            <div style={videoViewsStyle(this.props.photo)} className="mobile-tooltip__block mobile-tooltip__block_done">
                                <p style={getProgressColorView(this.props.photo)} className="tooltip_weight">{getProgress(this.props.photo.our_info_extra_1)}</p>
                                <p className="tooltip_mobile_likes">{declension(getCountMoneyTask(this.props.photo.our_info_extra_1), strings.VIEW, strings.VIEW_2, strings.VIEW_5)}</p>
                            </div>
                    
                        </div>
                    </div>
                
                    <form className="form-order">
                    <h2 className="h2 form-order__heading bot_header">{strings.WANT_MORE}</h2>
                    <div className="just_space_mobile">
                        <div style={getMobileDisplayStyle(this.props.photo, 1)} className="line-calc">
                            <input value={this.state.likes} ng-style="likesETStyle" onChange={this.likesValueChanged} type="number" min="0"
                            className="input add-subscribe-form__input input_width" name="Count"/>
                            <p className="order_type">{this.state.likesDecl}</p>
                            <p className="order_type text_price_order">2 {strings.PRICE_COINS_LIKES}</p>
                        </div>
                
                        <div style={getMobileDisplayStyle(this.props.photo, 2)} className="line-calc">
                            <input  ng-style="commentsETStyle" onChange={this.commentsValueChanged} value={this.state.comments} type="number" min="0"
                            className="input add-subscribe-form__input input_width" name="Count"/>
                            <p className="order_type">{this.state.commentsDecl}</p>
                            <p className="order_type text_price_order">10 {strings.PRICE_COINS_FOLLOWERS}</p>
                        </div>
                        <div style={getMobileDisplayStyle(this.props.photo, 3)} className="line-calc" ng-style="viewsETStyle">
                            <input ng-model="viewsValueMobile" onChange={this.viewsValueChanged} type="number" value={this.state.views} min="0"
                            className="input add-subscribe-form__input input_width" name="Count"/>
                            <p className="order_type">{this.state.viewsDecl}</p>
                            <p className="order_type text_price_order">10 {strings.PRICE_COINS_FOLLOWERS}</p>
                        </div>
                    </div>
                    <div style={{margin : '12px 0 0 0'}}>{strings.MIN_ORDER_LIKES}</div>
                    <div className="order_line"/>
                    <div className="form-order__score content_normal">
                        {this.state.isShowNotEnought && <NotEnoughtCoins arrowStyle={{top : 0, left : '50px'}} text={this.state.notEnoughtCoins}/>}
                        {this.state.isShowSessionError && <SessionErrorOrder arrowStyle={{top : 0, left : '50px'}} text={strings.SESSION_DIALOG_TEXT_1}/>}
                        <span className="text_left_oreder">{strings.TOTAL_PRICE}</span>
                        <span className="tooltip rate-content__tooltip price_mob_m">
                            <span className="form-order__sum sum_coins_mobile">{this.state.sum} {declension(this.state.sum, strings.COIN, strings.COIN_2, strings.COIN_5)}</span>
                        </span>
                    </div>
                    <div onClick={this.createOrder} style={(this.state.isLoading || this.state.sum < 20) ? getEnabledBtnStyle() : {}} className="btn btn_purple
                        form-order__btn mob_btn_order">{strings.CREATE_PROMOTION}
                        {this.state.isLoading && <BtnLoader />}
                    </div>
                    
                    </form>
            
              </div>
            ); 
        
        
    }
}
export default MobileOrder;

