import LocalizedStrings from 'react-localization'
export const getLocalizedStrings = () => {
    let strings = new LocalizedStrings({
        ru:{
          SIGN_IN_ASD: "Авторизация",
          ENTER_INSTA_NAME: "Введите имя пользователя от Instagram, чтобы подгрузить данные и фотографии аккаунта для раскрутки. Пароль не требуется.",
          INSTA_USERNAME: "Логин Instagram",
          INSTA_USERNAME_2: "Имя в Инстаграме",
          YOUR_PASSWORD : "Ваш пароль",
          ENTER_IN_INSTA : "ВОЙТИ",
          WROND_LOGIN_PASS : "Неверный username.",
          WROND_LOGIN_PASS_2 : "Нельзя использовать номер телефона или email.",
          OPEN_MOBILE_APP : 'Несуществующее имя пользователя',
          OPEN_MOBILE_APP_2 : ' и подвердите вход в аккаунт',
          WRONG_CHALLENGE : 'Неверный код',
          ON_NUMBER : 'На номер',
          ON_EMAIL : 'На email',
          SEND_6_CODE : 'отправлен 6-значный код.',
          ENTER_CODE : 'Введите его в поле ниже.',
          PRESS_ENTER : 'Нажимая кнопку Войти, вы принимаете условия',
          PRESS_ENTER_2 : 'политики обработки персональных данных',
          PRESS_ENTER_3 : 'и',
          PRESS_ENTER_4 : 'пользовательского соглашения',
          DONT_REMEMBER: 'Не помню пароль',
          MIN_ORDER_LIKES: 'Минимальный заказ - 10 (лайков/комментов/просмотров)',
          MIN_ORDER_FOLLOWERS: 'Минимальный заказ - 10 подписчиков',
      
          DO_TASKS_DIALOG: 'Выполни',
          DO_TASKS_DIALOG_2: 'одно задание',
          DO_TASKS_DIALOG_3: 'Чтобы подтвердить, что это твой аккаунт. Ведь мы не спросили у тебя пароль при входе.',
          DO_TASKS_DIALOG_4: 'После этого ты сможешь заказать накрутку для своего аккаунта.',
          NO_SPACES: 'Пробелы в имени не допустимы.',
      
          SESSION_DIALOG_TITLE: 'Подтверди аккаунт',
          SESSION_DIALOG_TEXT_1: 'Для подтверждения аккаунта выполни хотя бы одно задание из раздела "Получить монеты".',
          SESSION_SUCCESS_TITLE: 'Аккаунт успешно подтверждён',
          SESSION_SUCCESS_TEXT: 'Теперь ты можешь заказать накрутку для своего аккаунта. Или продолжай выполнять задания и зарабатывать монеты.',
      
          SESSION_FOLLOW_TITLE: 'За отписку аккаунт будет заблокирован',
          SESSION_FOLLOW_TEXT: 'Если ты часто подписываешься и потом быстро отписываешься от аккаунтов, то Instagram заподозрит неладное, и временно заблокирует твой аккаунт.',
          SESSION_COMMENT_TITLE: 'За повторяющиеся одинаковые комментарии Instagram может временно заблокировать твой аккаунт',
          SESSION_COMMENT_TEXT: 'Старайся оставлять осмысленные комментарии, связанные с фотографией или текстом поста.',
      
          
          PROMOTION_ACCEPT: 'Необходимо подтвердить аккаунт, чтобы накрутка стала доступна.',
          PROMOTION_ACCEPT_MORE: 'Подробнее',
      
          DO_TASKS: 'Выполнить',
          PRIVATE_PROFILA_AUTH: 'У вас приватный профиль, сделайте свой профиль открытым, чтобы пользоваться нашим сервисом.',
          DONT_ENTER_PHONE: 'Не вводите номер телефона. Используйте username из Инстаграм.',
          DONT_ENTER_EMAIL: 'Не вводите email. Используйте username из Инстаграм.',
          DIALOG_DO_TASKS: 'Чтобы сделать заказ, нужно выполнить хотя бы одно задание, это необходимо, чтобы удостовериться, что вы являетесь владельцем аккаунта.',
      
          FIRST_TIME_DESCR_TITLE: 'Вам может',
          FIRST_TIME_DESCR_TITLE_2: 'прийти',
          FIRST_TIME_DESCR_TITLE_3: 'предупреждение',
          FIRST_TIME_DESCR_1: 'Instagram может прислать тебе письмо на email или предупредить внутри приложения, что выполнен вход в аккаунт. Не беспокойся, это предупреждение значит, что ты успешно авторизовался в сервисе Instarlike, просто Instagram это воспринимает как подозрительную попытку входа.',
          FIRST_TIME_DESCR_2: 'Как я могу убедиться, что всё в порядке ?',
          FIRST_TIME_DESCR_3: '- Ты по-прежнему можешь войти в свой аккаунт в Instagram. Если бы аккаунт был взломан, то пароль сразу бы изменили, чтобы завладеть аккаунтом.',
          FIRST_TIME_DESCR_4: '- В разделе Настройки / Аккаунт/ Понравившиеся вам публикации нет подозрительной активности.Это значит, что никто, кроме тебя не ставит лайки и не подписывается на других людей ',
          FIRST_TIME_DESCR_5: 'Сервис Instarlike не производит никаких автоматических действий с аккаунтами, люди выполняют все задания вручную.',
          FIRST_TIME_DESCR_6: 'ПРОДОЛЖИТЬ',
      
          QUESTION_TITLE: 'Справка',
          QUESTION_OK: 'Понятно',
          QUESTION_TEXT: 'Данный сервис не производит никаких автоматических действий с аккаунтами, люди выполняют все задания вручную.',
          QUESTION_TEXT_2: 'Благодаря этому Инстаграм никак не сможет вычислить накрутку. Так мы заботимся о том, чтобы наши пользователи не попадали в теневые баны.',
          DONT_REMEMBER_TITLE: 'Не помню пароль',
          DONT_REMEMBER_TEXT: 'Просто восстанови свой пароль через официальное приложение Инстаграм.',
      
          DISABLE_2_FACTOR : 'Отключите двухфакторную аутентификацию в приложении Instagram для корректной работы с сервисом.',
          HOW_TO_DO_IT : 'Как это сделать?',
          TWO_FACTOR_DISABLED : 'АУТЕНТИФИКАЦИЯ ОТКЛЮЧЕНА',
          CONTINUE_WHITHOUT : 'Если вы продолжите работу с включенной двухфакторной аутентификацией, то вам будет доступна накрутка только за деньги. Функция заработка монет будет недоступна.',
          CONTINUE_WHITHOUT_BTN : 'ПРОДОЛЖИТЬ БЕЗ ОТКЛЮЧЕНИЯ',
          PUBLIC_PROFILE : 'Ваш профиль в Instagram должен быть публичным, чтобы накрутка работала корректно',
          READY_BTN : 'ГОТОВО',
          HOW_MAKE_PUBLIC : 'Как сделать профиль публичным:',
          HOW_MAKE_PUBLIC_1 : '1. Откройте приложение Instagram',
          HOW_MAKE_PUBLIC_2 : '2. Зайдите в Меню > Настройки > Кон- фиденциальность аккаунта.',
          HOW_MAKE_PUBLIC_3 : '3. Выключите параметр «Закрытый аккаунт».',
          CODE_CHALLENGE_TITLE : 'Код проверки',
          TWO_FACTOR_TITLE : 'Двухфакторная аутентификация',
          PUBLIC_ACC_TITLE : 'Сделайте ваш профиль публичным',
          OPEN_APP_INSTA : '1. Откройте приложение Instagram',
          OPEN_APP_INSTA_2 : '2. Зайдите в Меню > Настройки > Конфиденциальность и безопасность > Двухфакторная аутентификация',
          OPEN_APP_INSTA_3 : '3. Выключите функцию двухфакторной аутентификации',
          WROND_LOGIN_VIP : 'Введите Username от Инстаграма, НЕ вводите номер телефона',
      
          FAST_WRAPPING: 'Быстрый доступ к раскрутке аккаунтов Instarlike',
          FAST_WRAPPING_CLICK: 'Нажмите',
          FAST_WRAPPING_PICK: 'и выберите',
          FAST_WRAPPING_HOME: 'На экран «Домой»',
          FAST_WRAPPING_ANDROID: 'Раскрутка аккаунтов',
          FAST_WRAPPING_ANDROID_2: 'Инстаграм',
          FAST_WRAPPING_ANDROID_3: 'Добавить на главный экран',
      
          MENU_1 : 'Продвижение в Instagram*',
          MENU_2 : 'Получить монеты',
          MENU_3 : 'Пакетные предложения',
          MENU_4 : 'Заработать с нами',
          MENU_5 : 'Советы по продвижению',
          MENU_6 : 'Готовые сторис',
          MENU_7 : 'Раскрутка др. соцсетей',


          LICENSE : 'Пользовательское соглашение',
          GIFT_1 : 'Подари друзьям 50',
          GIFT_2 : 'и получи 20 монет',
          LEARN_MORE : 'ПОДРОБНЕЕ',
          ADD_ACCOUNT : 'Добавить аккаунт',
          EMAIL_TO_WRITE_US: 'Email для связи с нами admin@instarlike.com',
      
          BUY_COINS : 'Пополнить',
          COIN : 'монета',
          COIN_2 : 'монеты',
          COIN_5 : 'монет',
          COINS_DELETE: 'За отписку монеты и деньги будут вычтены',
      
          OPEN_DESCRIPTION : 'Развернуть описание',
          GET_FOLLOWERS : 'Добавить подписчиков',
          COUNT_FOLLOWERS : 'Количество подписчиков',
          PRICE_FOLLOWERS : 'Стоимость',
          ACCOUNT_PREVIEW : 'Предпросмотр аккаунта',
          CREATE_PROMOTION : 'Заказать',
          IN_PROCCESS : 'В процессе: добавлено',
          POSTS : 'постов',
          FOLLOWING : 'подписок',
          FOLLOWERS : 'подписчиков',
          PROMOTE_TASK : 'Раскрутить',
          IN_PROGRESS_TASK : 'В процессе',
          COMPLETED_TASK : 'Завершено',
          ALL_IN_PHOTO : 'Всего у фото',
          GET_FOLLOWERS_SETTINGS : 'Расширенные настройки',
          PRIVATE_PROFILE : 'Сделайте ваш профиль публичным, чтобы накрутка работала корректно',
          COMMENTS_OFF : 'У данной фотографии отключена возможность комментирования, не создавайте заказ с накруткой комментов.',
      
          LIKE : 'Лайк',
          LIKE_2 : 'Лайка',
          LIKE_5 : 'Лайков',
          COMMENT : 'Коммент',
          COMMENT_2 : 'Коммента',
          COMMENT_5 : 'Комментов',
          REPORT : 'Жалоба',
          REPORT_2 : 'Жалобы',
          REPORT_5 : 'Жалоб',
          VIEW : 'Просмотр',
          VIEW_2 : 'Просмотра',
          VIEW_5 : 'Просмотров',
      
          PRICE_COINS_LIKES: 'монеты/шт',
          PRICE_COINS_FOLLOWERS: 'монет/шт',
      
          MADE_ORDERED : 'Выполнено/заказано',
          LIKES : 'Лайки',
          COMMENTS : 'Комменты',
          COMMENTS_FULL : 'Комментарии',
          VIEWS : 'Просмотры',
          WANT_MORE : 'Хочу еще',
          TOTAL_PRICE : 'Итого',
          NO_PHOTOS : 'В аккаунте пока нет фотографий',
      
          BUY_COINS_TITLE : 'Пополнить баланс',
          YOUR_BALANCE : 'Ваш баланс',
          BUY_COINS_DESC : 'Купить монеты',
          PRICE : 'Стоимость',
          GET_DISCOUNT : 'При покупке 100 монет получи скидку 10%',
          COINS_COUNT : 'Монет',
          BUY : 'Купить',
          DISCOUNT : 'Скидка',
          DISCOUNT_BANNER: 'При покупке',
          DISCOUNT_BANNER_2: 'от 100 монет',
      
          CONFIRM_ORDER: 'Подтверждение заказа',
          CONFIRM_ORDER_BUY: 'Покупка',
          CONFIRM_ORDER_ACC: 'Для аккаунта',
          CONFIRM_ORDER_PRICE: 'Стоимость',
          CONFIRM_ORDER_BUY_BTN: 'Оплатить',
      
          BLOCK_ACC_1: 'За отписку будет штраф',
          BLOCK_ACC_2: 'будет заблокирован',

          FINE_TITLE: 'Ты разбиваешь наши сердца',
          FINE_TEXT: 'Мы заметили, что ты отписываешься от аккаунтов после выполнения задания и наложили штраф ',
          FINE_TEXT_2: 'Не делай больше так, пожалуйста. От этого страдает репутация нашего сервиса.',
      
          FOLLOW : 'Подписаться',
          NOT_ENOUGHT_COINS_TOOLTIP : 'Не хватает монет для данного заказа. Твой баланс',
          TOOLTIP_TASK : 'Выполняя задания на этой странице, вы получаете монеты, которые можете обменять на лайки, комментарии, новых подписчиков и просмотры видео.',
          DELETE_COINS_BANNER : 'За отписку мы вычитаем заработанные монеты',
          DELETED_COINS_BANNER: 'За отписки вычтено',
          GOT_IT : 'Понятно',
          COINS_FOR_COMMENT_5 : 'монеты за коммент',
          COINS_FOR_VIEW_5 : 'монеты за просмотр',
          WRITE_COMMENT : 'Напишите подходящий комментарий',
          WRITE_COMMENT_BUTTON : 'Написать коммент',
          WRITE_COMMENT_DESC : '(не менее 3 слов)',
          APPEAL : 'Пожаловаться',
          WATCH_VIDEO : 'Смотреть видео',
          COINS_FOR_LIKE_2 : 'монета за лайк',
          NO_TASKS : 'Заданий пока что нет',
          NEXT_TASK : 'Далее',
          LIKE_PHOTO_TASK_DESC: 'Перейди в Instagram и поставь лайк. Затем возвращайся и получи монеты.', 
          FOLLOW_PHOTO_TASK_DESC: 'Перейди в Instagram и подпишись на профиль. Затем возвращайся и получи монеты.',
          COMMENT_PHOTO_TASK_DESC: 'Перейди в Instagram и напиши положительный комментарий. Затем возвращайся и получи монеты.',
          VIDEO_TASK_DESCRIPTION: 'Перейди в Instagram и посмотри видео минимум 7 секунд. Затем возвращайся и получи монеты.',
      
          DISCOUNT_BANNER_TITLE_1 : 'Скидки при покупке от 100 монет',
          DISCOUNT_BANNER_TITLE_2 : 'Купи больше - заплати меньше',
          DISCOUNT_BANNER_TITLE_3 : 'Купить',
          INVITE_FRIENDS : 'Пригласи друзей на Instarlike и получи 20 монет',
          GIFT_FRIENDS : 'Подари друзьям 20 монет, чтобы они попробовали Instarlike.',
          GIFT_FRIENDS_AND_GET : 'И получи 20 монет, когда они пополнят свой счёт.',
          GIFT_FRIENDS_MORE : 'Подробнее',
          FROM : 'из',
          INVITE_FRIENDS_TITLE : 'Пригласить друзей',
          COPY_AND_SHARE: 'Твоя реферальная ссылка',
          COPY_AND_SHARE_2: 'Опубликуй эту ссылку в социальных сетях или блоге, чтобы заработать реальные деньги',
          COPY : 'Копировать',
          OR_SHARE : 'Или поделись ссылкой в соц. сетях:',
          YOUR_BONUCES : 'Твои бонусы',
          COIN_EARNED : 'монета заработана',
          COIN_EARNED_2 : 'монеты заработаны',
          COIN_EARNED_5 : 'монет заработано',
          INVITE : 'приглашение',
          INVITE_2 : 'приглашения',
          INVITE_5 : 'приглашений',
          ACCEPTED : 'принято',
          NEW_FOLLOWERS : 'Получи подписчиков и много лайков для твоего аккаунта в Instagram',
      
          COPY_AND_SHARE_2_NEW: 'Опубликуй эту ссылку в социальных сетях или блоге, чтобы заработать реальные деньги. Ты получишь за каждого приведенного пользователя по твоей персональной ссылке:',
          PRICE_OF: 'стоимости',
          ORDERS: 'его заказов',
          NEW_USER_GET: 'Новый пользователь также получает',
          INVITE_ACCEPTED: 'Приняли приглашение',
          TOP_5_EARNINGS: 'Топ-5 выплат',
          INSTARLIKE_PARTNERS: 'партнёрам instarlike',
      
          YOU_EARNED : 'Ты заработал',
      
          MIN_SUMM_PAYOUT : 'Вывод денег возможен только на',
          MIN_SUMM_PAYOUT_3 : 'WMZ (WebMoney)',
          MIN_SUMM_PAYOUT_2 : 'Минимальная сумма выплаты – 100₽',
      
          ERROR_LIKE_1 : 'Задание не было выполнено.',
          ERROR_LIKE_2 : 'Попробуйте еще раз.',

          CONFIRM_EMAIL : 'Введите ваш email для получения чека',
          EMAIL_PLACEHOLDER : 'Email',
          EMAIL_ERROR : 'Это обязательное поле',
      
          WITHDROW : 'СНЯТЬ',
          YOU_CAN_GET : 'Ты получаешь',
          COST_30 : '30% от стоимости заказов',
          COINS_20: '20 монет.',
          COINS_AFTER: 'После того, как количество приглашенных рефералов достигнет 100 человек, бонусные монеты начисляться перестанут.',
          FOR_ALL_USERS: 'всех пользователей, которые зарегистрируются по твоей реферальной  ссылке. Вывод денег возможен, если на счету более 100₽.',
          COINS_FOR_USERS_20: 'за каждого пользователя, который зарегистрировался по твоей реферальной ссылке. Новый пользователь также получает 50 монет.',
          COINS_FOR_USERS_20_50 : 'Новый пользователь также получает 50 монет.',
          CHOOSE_TYPE_WITHDRAW: 'Выбери способ оплаты',
          ENTERCARD_NUMBER: 'Введи номер карты',
          ENTERCARD_NUMBER_2: 'Номер карты',
          ENTER_EMAIL: 'Введи email для связи',
          YOUR_EMAIL: 'Ваш email',
          SEND_REQUEST: 'Отправить запрос',
          ENTER_YANDEX: 'Введи номер WMZ Кошелька',
          ENTER_YANDEX_2: 'Номер кошелька с буквы Z',
          ENTER_QIWI: 'Введи номер',
          WITHDROW_REQUEST_SEND: 'Запрос на вывод денег принят. Ожидайте поступление денег в течение 7 рабочих дней',
          WITHDROW_REQUEST_SEND_2: 'Если по истечении этого времени деньги так и не зачислятся, то напишите нам повторно – мы проверим ваш платёж.',
          WITHDROW_REQUEST_SEND_3: 'Понятно',
          ERROR_WITHDROW_MONEY: 'Вывод денег возможен, если на счету более 100₽.',
          ERROR_WITHDROW_WRONG_CARD: 'Неверные данные карты',
          ERROR_WITHDROW_WRONG_EMAIL: 'Неверный формат email',
          ERROR_WITHDROW_UNDEFINED_EMAIL: 'Введи свой email',
          ERROR_WITHDROW_UNDEFINED_CARD: 'Введи данные для оплаты',
          BUY_COINS_UNITPAY: 'Пополнение счета на',
          LIKE_BUTTON: 'Лайкнуть',
          LIMIT_50: 'Снять можно будет, когда накопится больше 100₽.',
          MORE_INFO_REF: 'Подробнее о том, как распространять реферальную ссылку,',
          MORE_INFO_REF_2: 'можно почитать тут.',
      
          MY_PROFILE : 'Мой профиль',
          LOG_OUT : 'Выйти',
          SEX : 'Пол',
          MEN : 'М',
          WOMEN : 'Ж',
          ALL_SEX : 'Все',
          AGE : 'Возраст',
          AGE_18 : 'До 18',
          AGE_18_25 : '18-25 лет',
          AGE_20_45 : '20-45 лет',
          AGE_40 : 'Больше 40 лет',
          AGE_40_MORE : '>40 лет',
          AGE_18_LOW : '<18 лет',
      
          SERVICE_PROMOTION: 'Сервис для раскрутки и продвижения аккаунтов Instagram.',
          SERVICE_PROMOTION_2: 'Instarlike – получи подписчиков и много лайков для твоего аккаунта в Instagram',
          
         
          FOllOWERS_CALC: 'Подписчики',
          USE_CALC: 'Использовать калькулятор',
          BUY_CALC: 'Купить',
          BUY_MORE_CALC: 'Купи больше -',
          PAY_LESS_CALC: 'заплати меньше',
          FOR_CALC: 'за',
          BUY_ANOTHER_CALC: 'Купить другое количество монет',
          WHAT_BUY_CALC: 'Что можно купить на монеты?',
          LIKE_CALC: 'лайк',
          FOLLOWER_CALC: 'подписчик',
          COMMENT_CALC: 'комментарий',
          VIEW_CALC: 'просмотр видео',
          END_CALC: 'Итого',
      
          GET_REAL_MONEY_SHARE: 'Заработай реальные',
          GET_REAL_MONEY_SHARE_2: 'деньги - просто',
          GET_REAL_MONEY_SHARE_3: 'поделись ссылкой',
          AUTH_USER_NOT_FOUND: 'Такой юзер не найден. Проверьте правильность написания вашего имени пользователя.',

          //не переведено
          MUST_HAVE_AVATAR: 'У вашего профиля в Инстаграм должен быть установлен аватар(фотография профиля).',
          PICK_LOGIN: 'Введите свой логин и выберите его из выпадающего списка',
          WATCH_MIN_7: 'Вернитесь и посмотрите видео минимум 7 секунд.',
          DO_TASK_TO_EARN: 'После этого ты сможешь вывести деньги.',
          PROMOTION_ACCEPT_EARN: 'Необходимо подтвердить аккаунт, чтобы вывести деньги.',
          PROMOTION_ACCEPT_EARN_ACCEPT: 'Подтверди свой аккаунт. Для этого выполни одно задание из раздела “Получить монеты”',
          ON_THIS_MOMENT: 'На данный момент',
          NO_NEW_TASKS: 'новых заданий нет',
          NOW_YOU_CAN: 'Теперь ты можешь',
          CHANGE_INSTA: 'сменить свой Инстаграграм аккаунт',
          AND_DO_TASKS_FROM_NEW: 'и выполнить задания от его имени. При этом не забудьте поменять аккаунт в самом приложении Инстаграм.',
          
          SHARE_LINK_TASK: 'Поделись ссылкой',
          SHARE_LINK_TASK_2: 'Получай за это монеты',
          SHARE_LINK_TASK_3: ' и реальные деньги',
          SHARE_LINK_TASK_PERCENTS: 'процент от заказов',
          FIND_MORE: 'Узнать больше',
          EARN_BY_WORDS: 'Заработай на словах!',
          EARN_BY_WORDS_TEXT: 'Платим монеты за статьи',
          EARN_BY_WORDS_TEXT_2: 'для нашего блога',
          EARN_BY_WORDS_PRICE: 'до 3 000 монет',
          SHARE_LINK_TITLE_DESC: 'Узнай подробнее по ссылке (откроется после нажатия на кнопку “Узнать больше”)',
          EARN_LINK_TITLE_DESC: 'Узнай о задании подробнее по ссылке',

          TG_TASK: 'Подпишись на наш',
          TG_TASK_2: 'Telegram-канал',
          TG_TASK_PRICE: '+2 монеты',

          UPLOAD_SCREEN_TITLE: 'Загрузите скриншот',
          UPLOAD_SCREEN_TEXT: 'На скриншоте должно быть видно, что у фотографии из задания стоит лайк (красное сердечко рядом с фотографией).',
          UPLOAD_SCREEN_GALLERY: 'Открыть галерею',
          UPLOADING_TEXT: 'проверяем',
          SCREEN_ERROR_TITLE: 'Cкриншот не прошёл проверку',
          SCREEN_ERROR_TEXT: 'На скриншоте должно быть видно, что у фотографии из задания стоит лайк (красное сердечко рядом с фотографией).',
          SCREEN_ERROR_TEXT_2: 'Если всё сделано правильно, а ошибка продолжает повторяться, напиши в нашу поддержку admin@instarlike.com',
          SCREEN_DESCRIPTION: 'Перейди в Instagram и поставь лайк.',
          SCREEN_DESCRIPTION_2: 'Сделай скриншот так, чтобы было видно красное сердечко рядом с фото из задания.',
          SCREEN_DESCRIPTION_3: 'Затем возвращайся, загрузи скриншот и получи монеты.',

          EMPTY_TASK_BONUS: 'Вааау!',
          EMPTY_TASK_BONUS_2: 'Ты выполнил все задания!',
          EMPTY_TASK_BONUS_3: 'Возвращайся позже, чтобы получить новые. А пока попробуй онлайн заработок у наших партнёров',
          EMPTY_TASK_BONUS_BTN: 'Перейти на Appmob.ru',

          COMBO_LIKE: 'Подписка + лайк',
          COMBO_COMMENT: 'Подписка + коммент',
          COMBO_VIEW: 'Подписка + просмотр',

          AND: 'и',
          USER_AGREEMENT: 'пользовательского соглашения',
          FROM_BALANCE_TEXT: 'Списать 10% от стоимости заказа с моего баланса',
          FROM_BALANCE_TEXT_ALL: 'Списать весь остаток с моего баланса',
          FROM_BALANCE_DESC: 'С баланса спишем',
          PAYMENT_RESULT_BACK: 'Вернитесь назад в приложение.',
          PAYMENT_RESULT_SUCCESS_TITLE: 'Оплата прошла успешно',
          PAYMENT_RESULT_SUCCESS_TEXT: 'Ваш баланс успешно пополнен.',
          PAYMENT_RESULT_FAIL_TITLE: 'Оплата не прошла',
          PAYMENT_RESULT_FAIL_TEXT: 'Вероятно, случился сбой — вернитесь в приложение и попробуйте ещё раз.',
          SHOP_SALE_TEXT: 'Теперь ты можешь оплатить деньгами, заработанными при выполнении заданий 10% от заказа монет.',
          PAYMENT_RESULT_SUCCESS_BTN: 'Перейти к продвижению',
          PAYMENT_RESULT_FAIL_BTN: 'Попробовать ещё раз',
          STORIES_TITLE: 'Сторис',
          REELS_TITLE: 'Рилсы',
          POSTS_TITLE: 'Посты',
         
          BANK_CARDS_RU: 'Банковской картой',
          BANK_CARDS_WORLD: 'Иностранной картой',
          PAYMENT_TYPE_TITLE: 'Выберите способ оплаты',


        },
        en : {
            MORE_INFO_REF: 'Learn more about how to distribute your referral link,',
           MORE_INFO_REF_2: 'you can read it here.',
            LIMIT_50: 'You can withdraw it when you accumulate more than 100 rubles.',
          SIGN_IN_ASD: "Sign In",
    ENTER_INSTA_NAME: "Enter your Instagram username to upload your data and photos for your account promotion.",
    INSTA_USERNAME: "Instagram username",
    INSTA_USERNAME_2: "Instagram username",
    YOUR_PASSWORD : "Your password",
    ENTER_IN_INSTA : "SIGN IN",
    WROND_LOGIN_PASS : "Sorry, your password was incorrect.",
    WROND_LOGIN_PASS_2 : "Please double-check your password.",
    OPEN_MOBILE_APP : 'Username not exist',
    OPEN_MOBILE_APP_2 : 'and sign in to your account.',
    WRONG_CHALLENGE : 'Wrong code',
    ON_EMAIL : 'On email',
    ON_NUMBER : 'On phone number',
    SEND_6_CODE : 'sent a 6-digit code.',
    ENTER_CODE : 'Enter it in the box below.',
    PRESS_ENTER : 'By clicking the login button, you accept the terms',
    PRESS_ENTER_2 : 'personal data processing policies',
    PRESS_ENTER_3 : 'and',
    PRESS_ENTER_4 : 'User Agreement',
    DISABLE_2_FACTOR : 'Disable two-factor authentication in the Instagram application to work correctly with the service.',
    HOW_TO_DO_IT : 'How to do it?',
    TWO_FACTOR_DISABLED : 'AUTHENTICATION DISABLED',
    CONTINUE_WHITHOUT : 'If you continue work with two-factor authentication enabled, then you will be available to cheat only for money. The coin earning function will be unavailable.',
    CONTINUE_WHITHOUT_BTN : 'CONTINUE WITHOUT SHUTDOWN',
    PUBLIC_PROFILE : 'Your Instagram profile must be public in order for the cheat to work correctly.',
    READY_BTN : 'READY',
    HOW_MAKE_PUBLIC : 'How to make a profile public:',
    HOW_MAKE_PUBLIC_1 : '1. Open the Instagram app',
    HOW_MAKE_PUBLIC_2 : '2. Go to Menu> Settings> Account Privacy.',
    HOW_MAKE_PUBLIC_3 : '3. Turn off the option "Private account".',
    CODE_CHALLENGE_TITLE : 'Security Code',
    TWO_FACTOR_TITLE : 'Two-factor authentication',
    PUBLIC_ACC_TITLE : 'Make your profile public',
    OPEN_APP_INSTA : '1. Open the Instagram app',
    OPEN_APP_INSTA_2 : '2. Go to Menu > Settings > Privacy & Security > Two-factor Authentication',
    OPEN_APP_INSTA_3 : '3. Turn off the two-factor authentication feature.',
    WROND_LOGIN_VIP : 'Enter Username from Instagram, do NOT enter phone number',

    MIN_SUMM_PAYOUT: 'Withdrawal of money is possible only',
    MIN_SUMM_PAYOUT_3: 'WMZ (WebMoney)',
    MIN_SUMM_PAYOUT_2: 'Minimum payout amount is 100 rubles',

    NO_SPACES: 'Spaces in the username are not valid.',
    DO_TASKS_DIALOG: 'Complete',
        DO_TASKS_DIALOG_2: 'one task',
        DO_TASKS_DIALOG_3: 'To confirm that this is your account. We didn’t ask you for a password at login.',
        DO_TASKS_DIALOG_4: 'After that, you will be able to order a cheat for your account.',

        PROMOTION_ACCEPT: 'Account must be verified for cheating to be available.',
            PROMOTION_ACCEPT_MORE: 'More',

    DO_TASKS: 'Do task',
        PRIVATE_PROFILA_AUTH: 'You have a private profile, make your profile public in order to use our service.',
        DONT_ENTER_PHONE: 'Do not enter a phone number. Use username from Instagram. ',
        DONT_ENTER_EMAIL: 'Do not enter an email. Use username from Instagram. ',
        DIALOG_DO_TASKS: 'To place an order, you must complete at least one task, this is necessary to make sure that you are the owner of the account.',

        SESSION_DIALOG_TITLE: 'Verify account',
            SESSION_DIALOG_TEXT_1: 'To confirm the account, they completed at least one task from the “Get Coins” section.',
            SESSION_SUCCESS_TITLE: 'Account successfully verified',
            SESSION_SUCCESS_TEXT: 'Now you can order a cheat for your account. Or continue to complete tasks and earn coins.',

    SESSION_FOLLOW_TITLE: 'Account will be suspended for unsubscribing',
     SESSION_FOLLOW_TEXT: 'If you often subscribe and then quickly unsubscribe from accounts, then Instagram will suspect something is wrong, and it will temporarily block your account.',
     SESSION_COMMENT_TITLE: 'For duplicate duplicate comments, Instagram may temporarily block your account',
     SESSION_COMMENT_TEXT: 'Try to leave meaningful comments related to the photo or post text.',

    COPY_AND_SHARE_2_NEW: 'Post this link on your social networks or blog to earn real money. You will receive for each referred user from your personal link: ',
        PRICE_OF: 'value',
        ORDERS: 'orders',
        NEW_USER_GET: 'The new user also receives',
        INVITE_ACCEPTED: 'Accepted the invitation',
        TOP_5_EARNINGS: 'Top 5 Payouts',
        INSTARLIKE_PARTNERS: 'instarlike partners',
    
    FIRST_TIME_DESCR_TITLE: 'You can',
        FIRST_TIME_DESCR_TITLE_2: 'get',
        FIRST_TIME_DESCR_TITLE_3: 'warning',
        FIRST_TIME_DESCR_1: 'Instagram can send you an email or warn you inside the application that you are logged in to your account. Don’t worry, this warning means that you have successfully logged in to the Instarlike service, it’s just that Instagram perceives it as a suspicious login attempt. ',
        FIRST_TIME_DESCR_2: 'How can I make sure everything is in order?',
        FIRST_TIME_DESCR_3: '- You can still log in to your Instagram account. If the account were hacked, then the password would immediately be changed to take possession of the account. ',
        FIRST_TIME_DESCR_4: '- There is no suspicious activity in the Settings / Account / Content you like section, which means that no one likes you and does not subscribe to other people',
        FIRST_TIME_DESCR_5: 'Instarlike service does not perform any automatic actions with accounts, people perform all tasks manually.',
        FIRST_TIME_DESCR_6: 'CONTINUE',

    DONT_REMEMBER: 'I do not remember the password',
    QUESTION_TITLE: 'Help',
    QUESTION_OK: 'I see',
    QUESTION_TEXT: 'This service does not perform any automatic actions with accounts, people perform all tasks manually.',
    QUESTION_TEXT_2: 'Thanks to this, Instagram will not be able to calculate the markup. This is how we ensure that our users do not fall into shadow bans. ',
    DONT_REMEMBER_TITLE: 'I do not remember the password',
    DONT_REMEMBER_TEXT: 'Just reset your password through the official Instagram application.',
    COINS_AFTER: 'After the number of invited referrals reaches 100 people, bonus coins will no longer be credited.',

    MENU_1 : 'Promote',
    MENU_2 : 'Get coins',
    MENU_3 : 'Package offers',
    MENU_4 : 'Earn with us',
    MENU_5 : 'Promotion Tips',
    LICENSE : 'User agreement',
    GIFT_1 : 'Invite your friend',
    GIFT_2 : 'and get 20 coins',
    LEARN_MORE : 'LEARN MORE',
    ADD_ACCOUNT : 'Add account',
    EMAIL_TO_WRITE_US: 'Email to contact us admin@instarlike.com',

    BUY_COINS : 'Buy coins',
    COIN : 'coin',
    COIN_2 : 'coins',
    COIN_5 : 'coins',
    COINS_DELETE: 'If you unfollow you will lose coins',

    ERROR_LIKE_1: 'Error checking execution.',
    ERROR_LIKE_2: 'Please try again',

    CONFIRM_ORDER: 'Confirm order',
    CONFIRM_ORDER_BUY: 'Purchase',
    CONFIRM_ORDER_ACC: 'For account',
    CONFIRM_ORDER_PRICE: 'Price',
    CONFIRM_ORDER_BUY_BTN: 'To pay',

    OPEN_DESCRIPTION : 'Open description',
    GET_FOLLOWERS : 'GET FOLLOWERS',
    COUNT_FOLLOWERS : 'Number of followers',
    PRICE_FOLLOWERS : 'Cost',
    ACCOUNT_PREVIEW : 'ACCOUNT PREVIEW',
    CREATE_PROMOTION : 'CREATE PROMOTION',
    IN_PROCCESS : 'In progress: added',
    POSTS : 'posts',
    FOLLOWING : 'following',
    FOLLOWERS : 'followers',
    PROMOTE_TASK : 'Promote',
    IN_PROGRESS_TASK : 'In progress',
    COMPLETED_TASK : 'Completed',
    ALL_IN_PHOTO : 'Total',
    GET_FOLLOWERS_SETTINGS : 'Advanced settings',
    PRIVATE_PROFILE : 'Make your profile public to get likes, comments and views work properly.',
    COMMENTS_OFF: 'This photo has disabled commenting, do not create an order with a wrap-up of comments.',
    WRITE_COMMENT_BUTTON: 'Write a comment',
    LIKE_PHOTO_TASK_DESC: 'Go to instagram and like the photo. Then come back and get coins.',
    FOLLOW_PHOTO_TASK_DESC: 'Go to instagram and follow profile. Then come back and get coins.',
    COMMENT_PHOTO_TASK_DESC: 'Go to instagram and write a positive comment. Then come back and get coins.',

    FAST_WRAPPING: 'Quick access to Instarlike account promotion',
    FAST_WRAPPING_CLICK: 'Click',
    FAST_WRAPPING_PICK: 'and select',
    FAST_WRAPPING_HOME: 'To the Home screen',
    FAST_WRAPPING_ANDROID: 'Promotion of accounts',
    FAST_WRAPPING_ANDROID_2: 'Instagram',
    FAST_WRAPPING_ANDROID_3: 'Add to Home Screen',

    LIKE : 'Like',
    LIKE_2 : 'Likes',
    LIKE_5 : 'Likes',
    COMMENT : 'Comment',
    COMMENT_2 : 'Comments',
    COMMENT_5 : 'Comments',
    REPORT : 'Report',
    REPORT_2 : 'Reports',
    REPORT_5 : 'Reports',
    VIEW : 'View',
    VIEW_2 : 'Views',
    VIEW_5 : 'Views',
    LIKE_BUTTON: 'Put like',
    MENU_6: 'Ready Stories',
    MENU_7: 'Other social promotion',
    PRICE_COINS_LIKES: 'coins/pc',
    PRICE_COINS_FOLLOWERS: 'coins/pc',

    MADE_ORDERED : 'Made/ordered',
    LIKES : 'Likes',
    COMMENTS : 'Comments',
    COMMENTS_FULL : 'Comments',
    VIEWS : 'Views',
    WANT_MORE : 'Want more',
    TOTAL_PRICE : 'Cost',
    NO_PHOTOS : 'No photos',

    BUY_COINS_TITLE : 'Buy Coins',
    YOUR_BALANCE : 'Your Balance',
    BUY_COINS_DESC : 'Buy Coins',
    PRICE : 'Cost',
    GET_DISCOUNT : 'Enjoy 10% discount for buying 100 coins.',
    COINS_COUNT : 'Coins',
    BUY : 'BUY',
    DISCOUNT : 'Discount',
    DISCOUNT_BANNER: 'When buying',
    DISCOUNT_BANNER_2: 'from 100 coins',
    MIN_ORDER_LIKES: 'Minimum order - 10 (likes/comments/views)',
    MIN_ORDER_FOLLOWERS: 'Minimum order - 10 followers',     

    PROMOTION_ACCEPT_EARN_ACCEPT: 'Verify your account. To do this, complete one task from the "Get coins" section',
    FOLLOW : 'Follow',
    TOOLTIP_TASK : 'Complete tasks on this page and get coins, which you can exchange on likes, comments, new followers or views.',
    GOT_IT : 'GOT IT',
    DELETE_COINS_BANNER: 'For unfollow we delete the earned coins.',
    DELETED_COINS_BANNER: 'For unfollow removed',

    NOT_ENOUGHT_COINS_TOOLTIP: 'There are not enough coins for this order. Your balance',

    COINS_FOR_COMMENT_5 : 'coins for comment',
    COINS_FOR_VIEW_5 : 'coins for view',
    WRITE_COMMENT : 'Write a suitable comment',
    WRITE_COMMENT_DESC : '(at least 3 words)',
    APPEAL : 'Report',
    WATCH_VIDEO : 'Watch video',
    COINS_FOR_LIKE_2 : 'coin for like',
    NO_TASKS : 'No tasks',
    NEXT_TASK : 'Next',
    VIDEO_TASK_DESCRIPTION: 'You will be transferred to instagram, start the video and watch at least 7 seconds',

    DISCOUNT_BANNER_TITLE_1: 'Discounts on purchases from 100 coins',
    DISCOUNT_BANNER_TITLE_2: 'Buy more - pay less',
    DISCOUNT_BANNER_TITLE_3: 'Buy coins',
    INVITE_FRIENDS : 'INVITE YOUR FRIEND TO INSTARLIKE AND GET 20 COINS',
    GIFT_FRIENDS : 'Give your friends 20 coins, so they will try Instarlike.',
    GIFT_FRIENDS_AND_GET : 'And get 20 coins when they put money in their accounts.',
    GIFT_FRIENDS_MORE : 'LEARN MORE',
    FROM : 'from',
    INVITE_FRIENDS_TITLE : 'Invite friends',
    COPY_AND_SHARE: 'Your referral link',
    COPY_AND_SHARE_2: 'Post this link on your social networks or blog to earn real money.',
    COPY : 'Copy',
    OR_SHARE : 'Or share a link in the social networks:',
    YOUR_BONUCES : 'Your bonuses',
    COIN_EARNED : 'coin earned',
    COIN_EARNED_2 : 'coins earned',
    COIN_EARNED_5 : 'coins earned',
    INVITE : 'invite',
    INVITE_2 : 'invites',
    INVITE_5 : 'invites',
    ACCEPTED : 'accepted',
    NEW_FOLLOWERS : 'Get followers and lots of likes for your Instagram account',

    BLOCK_ACC_1: 'Fine for unfollow',
    BLOCK_ACC_2: 'will be blocked',

    YOU_EARNED : 'YOU EARNED',
    WITHDROW : 'WITHDRAW MONEY',
    YOU_CAN_GET : 'You get',
    COST_30 : '30% from orders cost',
    COINS_20: '20 coins',
    FOR_ALL_USERS: 'all users who register by your referral link. Withdrawals are possible if you have more than 100 rub in your account.',
    COINS_FOR_USERS_20: 'for each user, who follow your rewarding link and register. New user will get 50 coins.',
    COINS_FOR_USERS_20_50 : 'New user will get 50 coins.',
    CHOOSE_TYPE_WITHDRAW: 'Select a Payment Method',
    ENTERCARD_NUMBER: 'Enter card number',
    ENTERCARD_NUMBER_2: 'Card number',
    ENTER_EMAIL: 'Enter email to contact',
    YOUR_EMAIL: 'Your email',
    SEND_REQUEST: 'Send request',
    ENTER_YANDEX: 'Enter number Yandex.Money',
    ENTER_YANDEX_2: 'Wallet number',
    ENTER_QIWI: 'Enter number',
    WITHDROW_REQUEST_SEND: 'Money withdrawal request accepted. In the near future it will be processed.',
    WITHDROW_REQUEST_SEND_2: 'In case of an error, we will contact you at the specified email.',
    WITHDROW_REQUEST_SEND_3: 'Close',
    ERROR_WITHDROW_MONEY: 'Withdrawal is possible if you have more than 100 rub',
    ERROR_WITHDROW_WRONG_CARD: 'Invalid card data',
    ERROR_WITHDROW_WRONG_EMAIL: 'Invalid email format',
    ERROR_WITHDROW_UNDEFINED_EMAIL: 'Enter your email',
    ERROR_WITHDROW_UNDEFINED_CARD: 'Enter payment details',
    BUY_COINS_UNITPAY: 'Top up for',

    MY_PROFILE : 'My profile',
    LOG_OUT : 'LOG OUT',
    SEX : 'Sex',
    MEN : 'M',
    WOMEN : 'F',
    ALL_SEX : 'All',
    AGE : 'Age',
    AGE_18 : 'less than 18 years',
    AGE_18_25 : '18-25 years',
    AGE_20_45 : '25-40 years',
    AGE_40 : 'more 40 years',
    AGE_40_MORE : '>40 years',
    AGE_18_LOW : '<18 years',

    BANK_CARDS_RU: 'Bank card',
    BANK_CARDS_WORLD: 'Foreign card',
    PAYMENT_TYPE_TITLE: 'Select payment method',

    SERVICE_PROMOTION: 'Service for promotion Instagram profiles.',
    SERVICE_PROMOTION_2: 'Instarlike - get followers and lots of likes for your Instagram account.',
    AUTH_USER_NOT_FOUND: 'No such user found. Check the spelling of your username.',
    FOllOWERS_CALC: 'Followers',
    USE_CALC: 'Use calculator',
    BUY_CALC: 'Buy',
    BUY_MORE_CALC: 'Buy more -',
    PAY_LESS_CALC: 'pay less',
         FOR_CALC: 'for',
         BUY_ANOTHER_CALC: 'Buy another coins count',
         WHAT_BUY_CALC: 'What i can buy for coins?',
         LIKE_CALC: 'like',
         FOLLOWER_CALC: 'follower',
         COMMENT_CALC: 'comment',
         VIEW_CALC: 'video view',
         END_CALC: 'Total',

         GET_REAL_MONEY_SHARE: 'Earn real',
             GET_REAL_MONEY_SHARE_2: 'money is easy',
             GET_REAL_MONEY_SHARE_3: 'share link',

             MUST_HAVE_AVATAR: 'Your profile in Instagram must have an avatar installed (profile picture).',
             PICK_LOGIN: 'Enter your username and select it from the drop-down list',
             WATCH_MIN_7: 'Go back and watch the video for at least 7 seconds.',
             DO_TASK_TO_EARN: 'After that, you can withdraw the money.',
             PROMOTION_ACCEPT_EARN: 'Account must be verified in order to withdraw money.',
             ON_THIS_MOMENT: 'At the moment',
             NO_NEW_TASKS: 'no new assignments',
             NOW_YOU_CAN: 'Now you can',
             CHANGE_INSTA: 'change your Instagram account',
             AND_DO_TASKS_FROM_NEW: 'and complete tasks on his behalf. At the same time, do not forget to change the account in the Instagram application itself.',
        
        
                SHARE_LINK_TASK: 'Share link',
                SHARE_LINK_TASK_2: 'Get coins for this',
                SHARE_LINK_TASK_3: 'and real money',
                SHARE_LINK_TASK_PERCENTS: 'percentage of orders',
                FIND_MORE: 'Find out more',
                EARN_BY_WORDS: 'Make money with words!',
                EARN_BY_WORDS_TEXT: 'Pay coins for articles',
                EARN_BY_WORDS_TEXT_2: 'for our blog',
                EARN_BY_WORDS_PRICE: 'up to 3,000 coins',
                SHARE_LINK_TITLE_DESC: 'Learn more at the link (opens after clicking on the Learn more button)',
                EARN_LINK_TITLE_DESC: 'Learn more about the task here',

                TG_TASK: 'Subscribe to our',
                TG_TASK_2: 'Telegram channel',
                TG_TASK_PRICE: '+2 coins',

                UPLOAD_SCREEN_TITLE: 'Upload screenshot',
                UPLOAD_SCREEN_TEXT: 'The screenshot should show that the photo from the assignment has a like (red heart next to the photo).',
                UPLOAD_SCREEN_GALLERY: 'Open Gallery',
                UPLOADING_TEXT: 'checking',
                SCREEN_ERROR_TITLE: 'Screenshot did not pass validation',
                SCREEN_ERROR_TEXT: 'The screenshot should show that the photo from the assignment has a like (red heart next to the photo).',
                SCREEN_ERROR_TEXT_2: 'If everything is done correctly, but the error keeps repeating, write to our support admin@instarlike.com',
                SCREEN_DESCRIPTION: 'Go to Instagram and like it.',
                SCREEN_DESCRIPTION_2: 'Take a screenshot so that you can see the red heart next to the photo from the mission.',
                SCREEN_DESCRIPTION_3: 'Then come back, upload a screenshot and get coins.',

                EMPTY_TASK_BONUS: 'Wow!',
                EMPTY_TASK_BONUS_2: 'You completed all tasks!',
                EMPTY_TASK_BONUS_3: 'Come back later to get new ones. In the meantime, try making money online with our partners',
                EMPTY_TASK_BONUS_BTN: 'Go to Appmob.ru',

                AND: 'and',
                USER_AGREEMENT: 'user agreement',
                FROM_BALANCE_TEXT: 'Write off 10% of the order value from my balance',
                FROM_BALANCE_DESC: 'We will write off the balance',
                FROM_BALANCE_TEXT_ALL: 'Write off all balance from my balance',

                PAYMENT_RESULT_BACK: 'Go back to the app.',
                PAYMENT_RESULT_SUCCESS_TITLE: 'Payment Successful',
                PAYMENT_RESULT_SUCCESS_TEXT: 'Your balance has been successfully topped up.',
                PAYMENT_RESULT_FAIL_TITLE: 'Payment Failed',
                PAYMENT_RESULT_FAIL_TEXT: 'Probably failed - please return to the application and try again.',
                SHOP_SALE_TEXT: 'Now you can pay 10% of your coin order with the money you earn from completing quests.',

                STORIES_TITLE: 'Stories',
                REELS_TITLE: 'Reels',
                POSTS_TITLE: 'Posts',
            }
       });
    return strings;
};
