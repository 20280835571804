/* eslint-disable eqeqeq */
import React from 'react';
import qs from 'querystring'
import {getRequestParams, saveInstaCookie, getInstaLogin, getSessionID} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import login_insta_android from './icons_insta_android/login_insta_android.svg'
import close from './icons_insta_android/close_insta_android.svg'
import {DebounceInput} from 'react-debounce-input';
import AuthDropdown from './custom_views/insta_custom_views/AuthDropdownAndroid';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { loadReCaptcha } from 'react-recaptcha-v3'
import { ReCaptcha } from 'react-recaptcha-v3'
import { getLocalizedStrings } from './utils/LocalizedStrings';
import { getTimestampToken, getRequestUrl, getHttpParams } from './utils/Utils';
import AuthTooltip from './custom_views/tooltips/AuthTooltip';
import  help_hover  from '../pages_android/custom_views/custom_icons/help_hover.png';
import { addLogin, setSessionId, getPromoId } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import BtnLoader from './custom_views/loaders/BtnLoader';
import { getUrlParameter } from './utils/UrlHelperAndroid';

var notEnabledBtn = {pointerEvents: "none",opacity: "1", background : "#cdaee8"}
var strings = getLocalizedStrings()
class Auth extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            users: [],
            
            isShowDropdown : false,
            pickedUser : {},
            errorText : "",
            isShowError : false,
            //timestamp : this.props.timestamp,
            isShowAuthTooltip : false,
            isSearching : false,
            isLoading : false,
            usernameText : ""
        }
        this.isAutoLogin = true
        
        console.log("getPromoId = " + getPromoId())
    }
    componentDidMount(){
        const urlUsername = getUrlParameter('username')
        if (getUrlParameter('isAddAccount') == undefined) {
            window.location.href = "/promotion"
        } else if (urlUsername != null && urlUsername != undefined && urlUsername != ""){
            this.setState({usernameText : urlUsername})
        }
    }
    // componentDidUpdate(prevProps){
    //     if (this.props != prevProps && this.props.timestamp != ""){
    //         this.checkAutoLogin(this.props.timestamp)
    //     }
    // }
    usernameTextChanged = (event) => {
        if (event){
            this.setState({usernameText : event.target.value})
        }
    }
    instaSearch = (event, isPick, recaptchaToken) => {
        var text = this.state.usernameText
        
            this.setState({searchValue: text, isShowError : false, pickedUser : {}})
            if (text.includes("+")) {
                this.setState({isShowError : true, errorText : strings.DONT_ENTER_PHONE})
               return
             }
             if (text.includes("@")) {
                this.setState({isShowError : true, errorText : strings.DONT_ENTER_EMAIL})
               return
             }
             if (text.trim().includes(" ")) {
                this.setState({isShowError : true, errorText : strings.NO_SPACES})
               return
             }
             if (text == undefined || text == "" || text == " "){
                return
             }
        
        var params = getRequestParams()
        this.setState({isSearching : false, isLoading : true})
        //params.timestamp = recaptchaToken ? recaptchaToken : this.state.timestamp
        if (isPick){
            params.login = getInstaLogin()
        } else {
            params.login = text
        }
        params.secretFlag = "1"
        //this.setState({isUpdateCaptcha : false})
        fetch(getRequestUrl() + "instaSearch", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                this.setState({isSearching : false})
                if (responseData.status == "OK") {
                    var users = responseData.user.users
                    this.setState({users : users})
                    // if (isPick && users.length > 0){
                    //     for (var i = 0; i < users.length; i++){
                    //         if(getInstaLogin().toLowerCase() == users[i].username.toLowerCase()){
                    //             this.userPicked(users[i])
                    //             break
                    //         }
                    //     }
                    // }
                    if (users.length > 0){
                        this.setState({pickedUser : users[0]})
                        this.registerUser(users[0])
                    } else {
                        this.setState({isLoading : false, isShowError : true, errorText : strings.AUTH_USER_NOT_FOUND})
                    }
                } 

                if (responseData.user && responseData.user.users.length == 0){
                    this.setState({isShowDropdown : false})
                }
            })       
    }
    // verifyCallback = (recaptchaToken) => {
    //     this.setState({timestamp : recaptchaToken})
    //     this.checkAutoLogin(recaptchaToken)        
    // }
    checkAutoLogin = (timestamp) => {
        if (this.isAutoLogin){
            this.isAutoLogin = false
            // var login = getInstaLogin()
            // if (login) {
            //     this.setState({searchValue : login})    
            //     this.instaSearch(undefined, true, timestamp)
            // }
        }
    }
    registerUser = (pickedUser) => {
        
        if (pickedUser.username){
            if (pickedUser.is_private){
                this.setState({isShowError : true, errorText : strings.PRIVATE_PROFILA_AUTH, isLoading : false})
                return
            }
            this.setState({isShowError : false, isLoading : true})
            var params = {
                'login' :  pickedUser.username,
                'promo_id' : getPromoId(),
                'is_web' : true,
                'is_vip' : true,
                'secretFlag' : '1',
                //'timestamp' : this.state.timestamp,
                'user_id' : pickedUser.pk,
                'sessionID' : getSessionID()
              }
           
            fetch(getRequestUrl() + "registerUser", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2) {
                        addLogin(pickedUser.username, pickedUser.profile_pic_url, pickedUser.pk)
                        saveInstaCookie(pickedUser.username, pickedUser.pk, pickedUser.profile_pic_url)
                        // if(pickedUser.profile_pic_url.includes("44884218_345707102882519_2446069589734326272")){
                        //     this.setState({isShowError : true, errorText : strings.MUST_HAVE_AVATAR})
                        // } else {
                        //     if (responseData.userOur.isSessionOk != undefined && !responseData.userOur.isSessionOk) {
                        //         window.location.href = '/promotion'
                        //       } else {
                        //         window.location.href = '/promotion'
                        //       }
                        // }     
                        window.location.href = '/promotion'           
                    } else if(responseData.response == -20){
                        this.setState({isShowError : true, errorText : strings.PRIVATE_PROFILA_AUTH})
                    }
                    console.log("registerUser = " + JSON.stringify(responseData))
                    this.setState({done : false, isLoading : false})
                })       
        } else {
            console.log("isShowError")
            this.setState({isShowError : true, errorText : strings.PICK_LOGIN})
        }
    }
    userPicked = (user) => { 
        this.setState({isShowDropdown : false, pickedUser : user, searchValue : user.username})
        console.log("userPicked = " + JSON.stringify(user))
    }
    clearPickedUser = () => {
        this.setState({isShowDropdown : false, pickedUser : {}, searchValue : "", isShowError : false})
    }
    openPolicy = () => {
        window.location.href = '/policy' 
    }
    openAgreement = () => {
        window.location.href = '/agreement' 
    }
    toogleAuthTooltip = () => {
        this.setState({isShowAuthTooltip : !this.state.isShowAuthTooltip}) 
    }
    render() {
            return (
                <div className="white_bg" id="authControllerID">
    
                    <div className="form-wrapper">
                        <h1 className="h1 form__header">{strings.SIGN_IN_ASD}</h1>
                        <div ng-style="loginPassStyle">
                            <p className="form__description">{strings.ENTER_INSTA_NAME}</p>
                            <div className="select">
                                <img className="select__img" src={this.state.pickedUser.profile_pic_url ? this.state.pickedUser.profile_pic_url : login_insta_android} alt=" "/>
                                <input
                                    className="form__input form__input_login"
                                    value={this.state.usernameText}
                                    placeholder="Логин Instagram"
                                    minLength={2}
                                    onChange={this.usernameTextChanged}
                                    />
                            
                                {/* <input value={this.state.searchValue} ng-change='searchInInsta()'
                                    id="loginInput" className="form__input form__input_login" type="text" name="login"
                                    placeholder="Логин Instagram" required/> */}
                                <img onClick={this.clearPickedUser} className={this.state.pickedUser.username ? "select__clear" : "not_display"} src={close} alt=" "/>
                                {(this.state.isShowDropdown || this.state.isSearching) && <AuthDropdown isSearching={this.state.isSearching} userPicked={this.userPicked} users={this.state.users}/>}
                                
    
                                
                            </div>
                            <span className={this.state.isShowError ? "form__error" : "not_display"}>{this.state.errorText}</span>
                
                            <div onClick={this.instaSearch} style={this.state.isLoading ? notEnabledBtn : {}} className="btn btn_purple form__btn sign_in_btn hover">{strings.ENTER_IN_INSTA}
                                {this.state.isLoading && <BtnLoader />}
                            </div>

                            <p className="form__description top_m_20">{strings.PRESS_ENTER} <b className="bold hover" onClick={this.openPolicy}>{strings.PRESS_ENTER_2}</b> {strings.AND} <b className="bold hover" onClick={this.openAgreement}>{strings.USER_AGREEMENT}</b></p>

                            
                        </div>
    
            
                    </div>
                    <div onClick={this.toogleAuthTooltip} className="circle__icon_help hover">
                        <img src={help_hover} className="img_help hover"/>
                    </div>
                    {this.state.isShowAuthTooltip && <AuthTooltip toogleAuthTooltip={this.toogleAuthTooltip}/>}
                   {/* {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey={getTimestampToken()}
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    }  */}
                
    
            </div>
            );
        
    }
}
export default Auth;